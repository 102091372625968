<template>
    <div>
        <bubble-menu
            v-if="editor"
            :editor="editor"
            :tippy-options="{ duration: 100 }"
            :should-show="shouldShow"
            class="bubble"
        >
            <button
                :class="{ 'is-active': editor.isActive('bold') }"
                @click="editor.chain().focus().toggleBold().run()"
            >
                <span class="icon">
                    <font-awesome-icon
                        icon="far fa-bold"
                        :color="iconColor"
                    />
                </span>
            </button>
            <button
                :class="{ 'is-active': editor.isActive('italic') }"
                @click="editor.chain().focus().toggleItalic().run()"
            >
                <span class="icon">
                    <font-awesome-icon
                        icon="far fa-italic"
                        :color="iconColor"
                    />
                </span>
            </button>
            <button
                :class="{ 'is-active': editor.isActive('underline') }"
                @click="editor.chain().focus().toggleUnderline().run()"
            >
                <span class="icon">
                    <font-awesome-icon
                        class="underline"
                        icon="far fa-underline"
                        :color="iconColor"
                    />
                </span>
            </button>
            <button
                :class="{ 'is-active': editor.isActive('code') }"
                @click="editor.chain().focus().toggleCode().run()"
            >
                <span class="icon">
                    <font-awesome-icon
                        class="code"
                        icon="fal fa-code"
                        :color="iconColor"
                    />
                </span>
            </button>
        </bubble-menu>
    </div>
</template>

<script>
import { BubbleMenu } from '@tiptap/vue-2'
import {isTextSelection} from '@tiptap/core'
export default {
    components: {
        BubbleMenu,
    },
    props: ['editor'],
    data() {
        return {
            size: 'md',
            iconColor: 'white'
        }
    },
    methods: {
        shouldShow({editor}) {
            const {view} = editor
            const { doc, selection } = view.state
            const { empty, from, to } = selection
            // Sometime check for `empty` is not enough.
            // Doubleclick an empty paragraph returns a node size of 2.
            // So we check also for an empty text size.
            const isEmptyTextBlock = !doc.textBetween(from, to).length
                && isTextSelection(selection)
            if (
                !view.hasFocus()
                || empty
                || isEmptyTextBlock
            ) {
                return false
            }
            return editor.isActive('paragraph') || editor.isActive('figcaption')
        }

    }
}
</script>

<style scoped lang="scss">
.bubble {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
button {
    background-color: black;
    border: none;
    padding: 1px 5px;
    cursor: pointer;
    .underline {
        margin-top: 0.1rem;
    }
}
button.is-active svg {
    color: $primary;
}
</style>
