<template>
    <div
        ref="dropdown"
        class="dropdown is-right"
        :class="{'is-active': active}"
    >
        <div class="dropdown-trigger">
            <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="active = !active"
            >
                <span class="icon is-small">
                    <font-awesome-icon
                        icon="fa-solid fa-circle-chevron-down"
                        size="lg"
                    />
                </span>
            </button>
        </div>
        <div
            id="dropdown-menu"
            class="dropdown-menu"
            role="menu"
        >
            <div class="dropdown-content">
                <a
                    class="dropdown-item"
                    @click="toggleNumbering"
                >
                    Toggle numbering
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeadingMenu',
    props: {
        node: {
            type: Object,
            required: true
        },
        updateAttributes: {
            type: Function,
            required: true,
        }
    },
    data() {
        return {
            active: false
        }
    },
    watch: {
        active: {
            handler(newActive) {
                if (newActive) {
                    window.addEventListener('click', this.closeMenuWhenClickOutside)
                } else {
                    window.removeEventListener('click', this.closeMenuWhenClickOutside)
                }
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeMenuWhenClickOutside)
    },
    methods: {
        closeMenuWhenClickOutside(e) {
            if (!this.$refs.dropdown.contains(e.target)){
                this.active = false
            }
        },
        toggleNumbering() {
            this.updateAttributes({ numbered: !this.node.attrs.numbered })
        }
    }
}

</script>

<style lang="scss" scoped>
.dropdown {
    position: absolute;
    right: 10px;
    .button {
        border: none;
        background: none;
        color: $primary;
        :hover {
            color: $primary;
        }
        :active {
            color: darken($primary, 20%)
        }
    }
}
</style>
