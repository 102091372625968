<template>
    <nav
        class="navbar is-primary is-fixed-top "
        role="navigation"
        aria-label="main navigation"
    >
        <div class="navbar-brand">
            <div class="navbar-item brand-name">
                Papermill
            </div>
            <div
                v-if="currentDocument"
                class="navbar-item"
            >
                <div class="separator" />
                <DocumentName />
            </div>
        </div>
        <div class="navbar-end">
            <div
                v-if="editor"
                class="otherUserContainer"
            >
                <div
                    v-for="user in otherUsers"
                    :key="user.clientId"
                    class="navbar-item"
                >
                    <div
                        class="otherUser has-tooltip-bottom"
                        :data-tooltip="user.name + ' is also editing this document'"
                        :style="{backgroundColor: user.color}"
                    >
                        {{ user.initials }}
                    </div>
                </div>
            </div>
            <div
                class="navbar-item has-dropdown user-menu"
                :class="{'is-active': showUserDropdown}"
            >
                <a
                    class="navbar-link dropdown-trigger"
                    @click="showUserDropdown = !showUserDropdown"
                >
                    {{ user ? user.email : '' }}
                </a>
                <div class="navbar-dropdown">
                    <a
                        class="navbar-item"
                        @click="logout"
                    >
                        Logout
                    </a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import {mapState} from 'vuex'
import DocumentName from './DocumentName.vue'


export default {
    name: 'PapermillNavbar',
    data() {
        return {
            showUserDropdown: false
        }
    },
    computed: {
        otherUsers() {
            if (!this.user || !this.editor || !this.editor.extensionStorage) return []
            let others = this.editor.extensionStorage.collaborationCursor.users.filter(
                user => user.papermillUserId !== this.user.uid
            )
            let uniqueUsers = {}
            others.forEach((user) => uniqueUsers[user.papermillUserId] = {
                color: user.color, initials: user.initials, name: user.name
            })
            return Object.values(uniqueUsers)
        },
        ...mapState(['currentDocument', 'editor', 'mode', 'showEditor', 'showPreview', 'showStyleEditor', 'user',
                     'docId'])
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push({name: 'Login'})
                })
        }
    },
    components: {DocumentName}
}
</script>

<style lang="scss" scoped>
nav.navbar {
    z-index: 15;
    .navbar-item {
        font-weight: 800;
        font-size: 14px;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-right: 0;
    }
    .navbar-brand {
        .separator {
            height: 40%;
            border-right: solid 1px white;
            margin-right: .3rem;
        }
        .brand-name {
            user-select: none;
        }
    }
    .navbar-end {
        .otherUserContainer {
            display: flex;
        }
        .otherUser {
            font-size: 0.7rem;
            color: darkslateblue;
            padding: 4px;
            border-radius: 4px;
        }
        .navbar-item {
            font-weight: 400;
        }
        .user-menu {
            padding: 0;
            margin-left: 0.5rem;
        }
    }
}
</style>

<style lang="scss">


@media screen and (max-width:1023px) {
    nav.navbar {
        .navbar-item, .navbar-link {
            color: white;
        }
        a.navbar-link.dropdown-trigger::after {
            border-color: white !important;
        }
        a.navbar-link.dropdown-trigger:hover {
            background-color: darken($primary,10%);
        }
        .navbar-end {
            .user-menu {
                .navbar-dropdown:hover {
                    background-color: darken($primary,10%);
                    color: white;
                }
                .navbar-item {
                    background-color: transparent;
                    color: white;
                }
            }
        }
        // UPTO HERE - need hover on small navbar dropdown (small screen) not to be white but primary
        // and get dropdown to expand
    }

}
</style>
