import {AlignmentType, convertInchesToTwip, LevelFormat, ShadingType} from 'docx'
import {a4} from './export_utils'
import {cmToTwip} from './docx_utils'

export const pageSize = a4
const omanosBlue = '#008dc7'
const omanosCodeBackground = '#D0E8F4'
const omanosGreen = '95C11F'
const omanosGrey = '#666666'
export const sectionIndent = cmToTwip(1.27)

export const pageMargins = {
    left: 2.54,
    top: 3.81,
    right: 2.54,
    bottom: 3.81,
    header: 2.54,
    footer: 1.27,
    gutter: 0
}

export const coverMargins = {
    left: 2.54,
    top: 3.81,
    right: 2.54,
    bottom: 3.81,
    header: 2.54,
    footer: 3.81,  // Google Docs it is 1.27,
    gutter: 0
}

export const figureSpacing = {
    before: 0.5,
    after: 0.5
}



/*
    Dimensions are 20ths of a point from the OOXML spec. AKA "TWIPs"
    Files are processed at 72DPI.
    Fonts are expressed in HALF points.
    https://startbigthinksmall.wordpress.com/2010/01/04/points-inches-and-emus-measuring-units-in-office-open-xml/
*/


export const numbering = {
    config: [
        {
            reference: 'omanos-numbering',
            levels: [
                {
                    level: 0,
                    format: LevelFormat.DECIMAL,
                    text: '%1.',
                    alignment: AlignmentType.START,
                    style: {
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(0.5),
                                hanging: convertInchesToTwip(0.25)
                            },
                        },
                    },
                },
                {
                    level: 1,
                    format: LevelFormat.LOWER_ROMAN,
                    text: '%2.',
                    alignment: AlignmentType.START,
                    style: {
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(1),
                                hanging: convertInchesToTwip(0.25)
                            },
                        },
                    },
                },
                {
                    level: 2,
                    format: LevelFormat.LOWER_LETTER,
                    text: '%3.',
                    alignment: AlignmentType.START,
                    style: {
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(1.5),
                                hanging: convertInchesToTwip(0.25)
                            },
                        },
                    },
                },
                {
                    level: 3,
                    format: LevelFormat.DECIMAL,
                    text: '%4.',
                    alignment: AlignmentType.START,
                    style: {
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(2),
                                hanging: convertInchesToTwip(0.25)
                            },
                        },
                    },
                },
                {
                    level: 4,
                    format: LevelFormat.LOWER_ROMAN,
                    text: '%5.',
                    alignment: AlignmentType.START,
                    style: {
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(2.5),
                                hanging: convertInchesToTwip(0.25)
                            },
                        },
                    },
                }
            ]
        },
        {
            reference: 'omanos-bullets',
            levels: [
                {
                    level: 0,
                    format: LevelFormat.BULLET,
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            left: convertInchesToTwip(5),
                            hanging: convertInchesToTwip(0.25)
                        }
                    }
                },
                {
                    level: 1,
                    format: LevelFormat.BULLET,
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            left: convertInchesToTwip(0.5),
                            hanging: convertInchesToTwip(0.25)
                        }
                    }
                },
                {
                    level: 2,
                    format: LevelFormat.BULLET,
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            left: convertInchesToTwip(0.75),
                            hanging: convertInchesToTwip(0.25)
                        }
                    }
                }
            ]
        },

    ]
}

export const styles = {
    default: {
        title: {
            run: {
                font: 'Source Sans Pro',
                size: 52,
                color: omanosBlue,
                bold: true,
            },
            paragraph: {
                spacing: {
                    before: 160,  // TWIPS i.e. points * 20
                    after: 120,   // TWIPS i.e. points * 20
                    line: 276     // line is measured in 240ths of a line!!!!!! Microsoft are crazy.
                },
            }
        },
        heading1: {
            run: {
                font: 'Source Sans Pro',
                size: 32,
                color: omanosBlue,
            },
            paragraph: {
                spacing: {
                    before: 18 * 20,  // TWIPS i.e. points * 20
                    after: 6 * 20,    // TWIPS i.e. points * 20
                    line: 276 // line is measured in 240ths of a line!!!!!! Microsoft are crazy.c
                }
            }
        },
        heading2: {
            run: {
                font: 'Source Sans Pro',
                size: 28,
                color: omanosBlue,
            },
            paragraph: {
                spacing: {
                    before: 18 * 20,
                    after: 6 * 20,  // TWIPS i.e. points * 20
                    line: 276
                },
                indent: {
                    left: sectionIndent
                },
            }
        },
        heading3: {
            run: {
                font: 'Source Sans Pro',
                size: 28,
                color: omanosGreen,
                bold: false
            },
            paragraph: {
                spacing: {
                    before: 18 * 20,
                    after: 6 * 20,  // TWIPS i.e. points * 20
                },
                indent: {
                    left: sectionIndent * 2
                },
            }
        },
        heading4: {
            run: {
                font: 'Source Sans Pro',
                size: 28,
                color: omanosGreen,
            },
            paragraph: {
                spacing: {
                    before: 18 * 20,
                    after: 6 * 20,  // TWIPS i.e. points * 20
                },
                indent: {
                    left: sectionIndent * 3
                },
            }
        },
        heading5: {
            run: {
                font: 'Source Sans Pro',
                size: 22,
                color: omanosGrey,
            },
            paragraph: {
                spacing: {
                    before: 18 * 20,
                    after: 6 * 20,  // TWIPS i.e. points * 20
                },
                indent: {
                    left: sectionIndent * 4
                },
            }
        },
        listParagraph: {
            paragraph: {
                spacing: {
                    before: 0,
                    after: 0
                }
            }
        }
    },

    paragraphStyles: [
        {
            name: 'Normal',
            id: 'normal',
            run: {
                font: 'Source Sans Pro',
                size: 22
            },
            paragraph: {
                spacing: {
                    line: 253, // Twips, perhaps?
                    after: 120  // think they are currently using blank spaces
                }
            }
        },
        {
            name: 'Pretitle',
            id: 'pretitle',
            run: {
                font: 'Source Sans Pro',
                size: 30,
                color: omanosGreen,
                bold: true
            },
            paragraph: {
                spacing: {
                    line: 276,  // Twips, perhaps?
                }
            }
        },
        {
            name: 'Caption',
            id: 'caption',
            run: {
                font: 'Source Sans Pro',
                size: 22,
                color: omanosGreen
            },
            paragraph: {
                spacing: {
                    before: 240,
                    after: 80  // TWIPS i.e. points * 20
                },
            }
        },
        {
            name: 'Header',
            id: 'header',
            run: {
                font: 'Source Sans Pro',
                size: 24,
                bold: true,
                color: omanosGreen
            }
        },
        {
            name: 'Footer',
            id: 'footer',
            run: {
                font: 'Source Sans Pro',
                size: 18,
                color: '808080'
            }
        },
        {
            name: 'Code Block',
            id: 'codeBlock',
            paragraph: {
                spacing: {
                    before: cmToTwip(0.5),
                    after: cmToTwip(0.5)
                }
            },
            run: {
                font: 'Ubuntu Mono',
                size: 22,
            }
        },

    ],
    characterStyles: [
        {
            name: 'Inline Code',
            id: 'inlineCode',
            run: {
                font: 'Ubuntu Mono',
                size: 22,
                color: '404040',  // 19% grey
                shading: {
                    type: ShadingType.SOLID,
                    color: omanosCodeBackground
                },
            }
        }
    ]


}

const white = '#ffffff'
export const codeColors = {
    fillColor: white,
    borderColor: Array(4).fill(omanosBlue)
}
