import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import {db} from '@/db'
import dal from '../dal'
import {createEditor} from '@/editor'
import {toast} from 'bulma-toast'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // Custom user object based on Firebase hook data
        user: undefined,
        // Firebase binds to /users/X, /docs/X, /styles/X
        profile: undefined,
        currentDocument: undefined,
        currentStyle: undefined,
        // TipTap
        editor: undefined,
        anchorMap: undefined,  // a map of fig, headings by id for cross-referencing
        // UI - mode and two sidebars
        mode: 'loading',
        outline: [],
        showShareModal: false,
        showStyleEditor: false,
        showDocList: false,
        topLevelNodes: {},
    },
    mutations: {
        ...vuexfireMutations,
        // this is our custom user map (obj) with a few simple fields
        setUser(state, user) {
            state.user = user
        },
        // focus vs preview
        setMode(state, mode) {
            state.mode = mode
        },
        // the tiptap editor instance, shared by e.g. the UI and the exporters
        setEditor(state, editor) {
            state.editor = editor
        },
        setAnchorMap(state, anchorMap) {
            state.anchorMap = anchorMap
        },
        setOutline(state, outline) {
            state.outline = outline
        },
        setTopLevelNodes(state, topLevelNodes) {
            state.topLevelNodes = topLevelNodes
        },
        showShareModal(state) {
            state.showShareModal = true
        },
        hideShareModal(state) {
            state.showShareModal = false
        },
        // sidebar toggles
        toggleDocList(state) {
            state.showDocList = !state.showDocList
        },
        toggleStyleEditor(state) {
            state.showStyleEditor = !state.showStyleEditor
        }
    },
    // used outside of view components
    getters: {
        anchorMap(state) {
            return state.anchorMap
        },
        currentStyle(state) {
            return state.currentStyle
        },
        editor(state) {
            return state.editor
        },
        outline(state) {
            return state.outline
        },
        profile(state) {
            return state.profile
        },
        topLevel(state) {
            return state.topLevelNodes
        }
    },
    actions: {

        // Main Firebase Bindings: User Profile, Current Document, Current Style
        bindProfile: firestoreAction(({ bindFirestoreRef }, userId) => {
            return bindFirestoreRef('profile', db.collection('users').doc(userId))
        }),

        unbindProfile: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('profile')
        }),

        bindCurrentDocument: firestoreAction(({ bindFirestoreRef }, docId) => {
            return bindFirestoreRef('currentDocument', db.collection('docs').doc(docId))
        }),

        unbindCurrentDocument: firestoreAction(({ unbindFirestoreRef }) => {
            return unbindFirestoreRef('currentDocument')
        }),

        bindCurrentStyle: firestoreAction(({ bindFirestoreRef }, styleId) => {
            return bindFirestoreRef('currentStyle', db.collection('styles').doc(styleId))
        }),

        unbindCurrentStyle: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('currentStyle')
        }),

        // In main.js we set a callback: if firebase changes user status, dispatch this action
        // Store simple user data and bind/unbind profile
        updateUser({dispatch}, user) {
            if (user) {
                dispatch('login', {
                    email: user.email,
                    uid: user.uid,
                })
            } else {
                dispatch('logout')
            }
        },

        login({commit, dispatch, state}, user) {
            commit('setUser', user)
            return dispatch('bindProfile', user.uid)
                .then(() => {
                    dispatch('bindCurrentStyle', state.profile.defaultStyle.id)
                        .then(() => {
                            if (state.profile.lastDocumentId) {
                                dispatch('openDocument', state.profile.lastDocumentId)
                                    .catch((err) => {
                                        commit('setMode', 'empty')
                                        toast({
                                            message: `Error - could not open most recent document ${err.message}`,
                                            type: 'is-danger',
                                            duration: 3000,
                                            position: 'bottom-center'
                                        })
                                    })
                            } else {
                                commit('setMode', 'empty')  // no last doc to open
                            }
                            window.Intercom('boot', {
                                app_id: 'azyfa82b',
                                email: state.user.email,
                                name: state.profile.name,
                                user_id: state.user.uid,
                            })
                        })
                })
        },

        logout({commit, dispatch}) {
            return dispatch('unbindProfile')
                .then(() => {
                    return dispatch('unbindCurrentStyle')
                        .then(() => {
                            return dispatch('unbindCurrentDocument')
                                .then(() => {
                                    firebase.auth().signOut()
                                        .then(() => {
                                            commit('setUser', undefined)
                                            window.Intercom('shutdown')
                                            window.Intercom('boot', {
                                                app_id: 'azyfa82b',
                                            })
                                        })
                                })
                        })

                })
        },

        newDocument({dispatch, state}) {
            dal.createDocument(state.user.uid)
                .then((docId) => {
                    dispatch('openDocument', docId)
                        .then(() => {
                            toast({
                                message: 'New document created',
                                duration: 3000,
                                position: 'bottom-center'
                            })
                        })
                })
        },

        deleteDocument({commit, dispatch, state}, {userId, docId}) {
            if (state.currentDocument && state.currentDocument.id === docId) {
                return dispatch('closeDocument')
                    .then(() => {
                        dal.setLastDocumentId(userId, null)
                            .then(() => {
                                commit('setMode', 'empty')
                                return dal.deleteDocument(userId, docId)
                            })
                    })
            } else {
                return dal.deleteDocument(userId, docId)
            }
        },

        openDocument({commit, dispatch, state}, docId) {
            commit('setMode', 'loading')
            let docRef = db.collection('docs').doc(docId)
            let oldEditor = state.editor
            return docRef.get().then((doc) => {
                if (doc.exists) {
                    return dispatch('bindCurrentDocument', docId)
                        .then(() => {
                            if (state.currentDocument.lastStyleId) {
                                return dispatch('bindCurrentStyle', state.currentDocument.lastStyleId)
                                    .then(() => {
                                        return dispatch('createEditor')
                                            .then(() => {
                                                commit('setMode', 'focus')
                                                if (oldEditor) {
                                                    oldEditor.provider.destroy()
                                                    oldEditor.destroy()
                                                }
                                                return dal.setLastDocumentId(state.user.uid, docId)
                                            })
                                    })
                            } else {
                                return dispatch('createEditor')
                                    .then(() => {
                                        commit('setMode', 'focus')
                                        if (oldEditor) {
                                            oldEditor.provider.destroy()
                                            oldEditor.destroy()
                                        }
                                        return dal.setLastDocumentId(state.user.uid, docId)
                                    })
                            }
                        })
                } else {
                    commit('setMode', 'empty')
                    return Promise.reject(new Error('Document not found'))
                }
            })
        },

        destroyEditor({commit, state}) {
            const oldEditor = state.editor
            commit('setEditor', undefined)
            if (oldEditor) {
                oldEditor.provider.disconnect()
                oldEditor.provider.destroy()
                oldEditor.destroy()
            }
        },

        // Close the document and destroy the editor
        // This should not be used when switching docs - Firebase will unbind automatically
        // and destroying the editor there needs to be done carefully to avoid upsetting the UI
        closeDocument({dispatch} ) {
            return dispatch('unbindCurrentDocument')
                .then(()=> {
                    dispatch('destroyEditor')
                })
        },

        createEditor({commit, state}) {
            return createEditor(state.currentDocument.id, {name: state.profile.name, uid: state.user.uid})
                .then((editor) => {
                    commit('setEditor', editor)
                })
        },

        selectStyle({dispatch, state}, styleId) {
            return dispatch('bindCurrentStyle', styleId)
                .then(() => dal.setLastStyleId(state.user.uid, state.currentDocument.id, styleId))
        },

        deleteCurrentStyle({state, commit}) {
            let id = state.currentStyle.id
            commit('setCurrentStyle', state.defaultStyle)
            return dal.deleteStyle(id)
        },

    },

})


export default store
