export function fontURL(fontname, options) {
    let suffix = 'Regular'
    let extension = 'ttf'
    if (options.light) {
        suffix = 'Light'
    }
    if (options.bold) {
        if (options.italics) {
            suffix = 'BoldItalic'
        } else {
            suffix = 'Bold'
        }
    } else if (options.italics) {
        suffix = 'Italic'
    }
    if (options.otf) {
        extension = 'otf'
    }
    return window.location.protocol + '//' + window.location.host + `/fonts/${fontname}-${suffix}.${extension}`
}

export function fonts() {
    return {
        Cormorant: {
            normal: fontURL('Cormorant', {}),
            bold: fontURL('Cormorant', {bold: true}),
            italics: fontURL('Cormorant', {italics: true}),
            bolditalics: fontURL('Cormorant', {italics: true, bold: true}),
        },
        Lato: {
            normal: fontURL('Lato', {}),
            bold: fontURL('Lato', {bold: true}),
            italics: fontURL('Lato', {italics: true}),
            bolditalics: fontURL('Lato', {italics: true, bold: true}),
        },
        Monserrat: {
            normal: fontURL('Montserrat', {otf:true}),
            bold: fontURL('Montserrat', {bold: true, otf: true}),
            italics: fontURL('Montserrat', {italics: true, otf:true}),
            bolditalics: fontURL('Montserrat', {italics: true, bold: true, otf:true}),
        },
        Nunito: {
            normal: fontURL('Nunito', {}),
            bold: fontURL('Nunito', {bold: true}),
            italics: fontURL('Nunito', {italics: true}),
            bolditalics: fontURL('Nunito', {italics: true, bold: true}),
        },
        'Open Sans': {
            normal: fontURL('OpenSans', {}),
            bold: fontURL('OpenSans', {bold: true}),
            italics: fontURL('OpenSans', {italics: true}),
            bolditalics: fontURL('OpenSans', {italics: true, bold: true}),
        },
        Roboto: {
            normal: fontURL('Roboto', {}),
            bold: fontURL('Roboto', {bold: true}),
            italics: fontURL('Roboto', {italics: true}),
            bolditalics: fontURL('Roboto', {italics: true, bold: true}),
        },
        'Source Sans Pro': {
            normal: fontURL('SourceSansPro', {otf:true}),
            bold: fontURL('SourceSansPro', {bold: true, otf: true}),
            italics: fontURL('SourceSansPro', {italics: true, otf:true}),
            bolditalics: fontURL('SourceSansPro', {italics: true, bold: true, otf:true}),
        },
        Tinos: {
            normal: fontURL('Tinos', {}),
            bold: fontURL('Tinos', {bold: true}),
            italics: fontURL('Tinos', {italics: true}),
            bolditalics: fontURL('Tinos', {italics: true, bold: true}),
        },
        'Ubuntu Mono': {
            normal: fontURL('UbuntuMono', {}),
            bold: fontURL('UbuntuMono', {bold: true}),
            italics: fontURL('UbuntuMono', {italics: true}),
            bolditalics: fontURL('UbuntuMono', {italics: true, bold: true}),
        },
    }
}
