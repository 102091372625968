<template>
    <div class="block-buttons">
        <button
            class="button has-tooltip-left"
            data-tooltip="Paragraph"
            @click="editor.chain().focus().setParagraph().run()"
        >
            <span class="icon">
                <font-awesome-icon
                    :icon="
                        editor &&
                            editor.isActive('paragraph') &&
                            (!(editor.isActive('bulletList') || editor.isActive('orderedList')))
                            ? 'fa-regular fa-paragraph' : 'fa-light fa-paragraph'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Section Heading"
            @click="editor && !editor.isActive('table') && editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('heading', { level: 1 })
                        ? 'fa-regular fa-h1' : 'fa-light fa-h1'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Subsection Heading"
            @click="editor && !editor.isActive('table') && editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('heading', { level: 2 }) ? 'fa-regular fa-h2' : 'fa-light fa-h2'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Subsubsection Heading"
            :class="{active: editor && editor.isActive('heading', { level: 3 })}"
            @click="editor && !editor.isActive('table') && editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('heading', { level: 3 })
                        ? 'fa-regular fa-h3' : 'fa-light fa-h3'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Depth four Heading"
            :class="{active: editor && editor.isActive('heading', { level: 4 })}"
            @click="editor && !editor.isActive('table') && editor.chain().focus().toggleHeading({ level: 4 }).run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('heading', { level: 4 })
                        ? 'fa-regular fa-h4' : 'fa-light fa-h4'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Depth five Heading"
            :class="{active: editor && editor.isActive('heading', { level: 5 })}"
            @click="editor && !editor.isActive('table') && editor.chain().focus().toggleHeading({ level: 5 }).run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('heading', { level: 5 })
                        ? 'fa-regular fa-h5' : 'fa-light fa-h5'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Bullet List"
            @click="editor.chain().focus().toggleBulletList().run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('bulletList') ? 'fa-regular fa-list-ul' : 'fa-light fa-list-ul'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="OrderedList"
            @click="editor.chain().focus().toggleOrderedList().run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('orderedList') ? 'fa-regular fa-list-ol' : 'fa-light fa-list-ol'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Figure"
            @click="insertImageFigure"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('imageFigure') ? 'fa-regular fa-image' : 'fa-light fa-image'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Table"
            @click="insertTableFigure"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('table') ? 'fa-regular fa-table' : 'fa-light fa-table'"
                />
            </span>
        </button>

        <div class="sep" />

        <button
            class="button has-tooltip-left"
            data-tooltip="Code"
            @click="editor && !editor.isActive('table') && editor.chain().focus().toggleCodeBlock().run()"
        >
            <span class="icon is-small">
                <font-awesome-icon
                    :icon="editor && editor.isActive('codeBlock') ? 'fa-regular fa-code' : 'fa-light fa-code'"
                />
            </span>
        </button>
    </div>
</template>

<script>
export default {
    props: ['editor'],
    methods: {
        insertHeading() {
            this.editor.chain().focus().insertContent({
                type: 'papermillHeading',
                content: [
                    {
                        type: 'heading'
                    },
                ],
            }).run()
        },
        insertImageFigure() {
            if (this.editor.isActive('orderedList')
                || this.editor.isActive('bulletList')
                || this.editor.isActive('table')) {
                return
            }
            this.editor.chain().focus().insertContent({
                type: 'imageFigure',
                content: [
                    {
                        type: 'image'
                    },
                    {
                        type: 'figcaption',
                        content: [
                        ],
                    },

                ],
            }).run()
        },
        insertTableFigure() {
            if (this.editor.isActive('orderedList')
                || this.editor.isActive('bulletList')
                || this.editor.isActive('table'))
            {
                return
            }
            this.editor.chain().focus().insertContent({
                type: 'tableFigure',
                content: [
                    {
                        type: 'table',
                        content: [
                            {
                                type: 'tableRow',
                                content: [
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                ]
                            },
                            {
                                type: 'tableRow',
                                content: [
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                ]
                            },
                            {
                                type: 'tableRow',
                                content: [
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                    {type: 'tableCell', content: [{type: 'paragraph'}]},
                                ]
                            }
                        ],
                    },
                    {
                        type: 'figcaption',
                    },
                ],
            }).run()
        }
    }
}
</script>

<style lang="scss" scoped>
.block-buttons {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px lightgrey;
    border-radius: 5px;
    box-shadow: 0px 1px 4px 0px #00000040;
    padding: 0.2rem;
    z-index: $z-toolbars;
    background-color: white;
    button.button {
        border: none;
        padding-top: 1rem;
        padding-bottom: 1rem;

    }
    .sep {
        width: 80%;
        border-bottom: 1px solid lightgray;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
    }
}
</style>
