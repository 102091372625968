<template>
    <input
        class="input placeholder-val"
        :value="currentValue"
        :placeholder="inputPlaceholder"
        @input="updatePlaceholder(placeholder.name, $event.target.value)"
    >
</template>

<script>
import dal from '@/dal'
import {debounce} from 'lodash'

export default {
    name: 'PlaceholderText',
    props: {
        document: {
            required: true,
            type: Object,
        },
        placeholder: {
            required: true,
            type: Object,
        }
    },
    data() {
        return {
            currentValue: '',
            debouncedUpdate: undefined
        }
    },
    computed: {
        inputPlaceholder() {
            return this.placeholder.default ?? this.placeholder.name
        }
    },
    watch: {
        document: {
            immediate: true,
            handler(doc) {
                if (doc.placeholders && doc.placeholders[this.placeholder.name]) {
                    this.currentValue = doc.placeholders[this.placeholder.name]
                }
            }
        }
    },
    beforeMount() {
        this.debouncedUpdate = debounce(
            (docId, key, val) => dal.setPlaceholder(docId, key, val),
            1000
        )
    },
    methods: {
        updatePlaceholder(key, val) {
            this.debouncedUpdate(this.document.id, key, val)
        }
    },

}
</script>

<style lang="scss" scoped>
.placeholder-val {
    width: 100%;
}
</style>
