<template>
    <div class="placeholder">
        <div class="placeholder-name">
            {{ placeholder.name }}
        </div>
        <PlaceholderImage
            v-if="placeholder.type === 'Image'"
            :document="document"
            :placeholder="placeholder"
        />
        <PlaceholderText
            v-else
            :document="document"
            :placeholder="placeholder"
        />
    </div>
</template>

<script>
import PlaceholderImage from '@/components/PlaceholderImage'
import PlaceholderText from '@/components/PlaceholderText'
export default {
    name: 'PlaceholderField',
    components: {PlaceholderImage, PlaceholderText},
    props: {
        document: {
            required: true,
            type: Object,
        },
        placeholder: {
            required: true,
            type: Object,
        }
    },

}
</script>

<style scoped lang="scss">
.placeholder {
    margin-top: 1rem;
    width: 100%;
    .placeholder-name {
        text-transform: uppercase;
        font-size: 0.7rem;
        width: 100%;
    }
}
</style>
