<template>
    <div class="table-container">
        <table class="table is-striped is-fullwidth">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Editor?</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="user in users"
                    :key="user.id"
                >
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                        <input
                            type="checkbox"
                            :checked="user.editor"
                            @click.prevent="toggleEditor(user)"
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import dal from '@/dal'
export default {
    props: ['users'],
    computed: {
        ...mapState(['currentDocument', 'profile'])
    },
    methods: {
        toggleEditor(user) {
            dal.setEditor(this.currentDocument.id, user.id, !user.editor)
        }
    }
}
</script>

<style>

</style>