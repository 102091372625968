import * as CraftProspectPDF from './craft_prospect_pdf'
import * as CraftProspectSmallPDF from './craft_prospect_small_pdf'
import * as OmanosAnalyticsWord from './omanos_word'
import * as OmanosAnalyticsPDF from './omanos_pdf'

const exporters = {
    'Omanos Analytics': OmanosAnalyticsPDF,
    'Omanos Analytics (Word)': OmanosAnalyticsWord,
    'Craft Prospect': CraftProspectPDF,
    'Craft Prospect (Small)': CraftProspectSmallPDF
}

// Download the document
export function download(json, doc, filename, style) {
    return exporters[style.name].download(json, doc, filename)
}

// Get doc as base64
export async function docToBase64(json, doc, style) {
    return await exporters[style.name].docToBase64(json, doc)
}
