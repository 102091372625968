<template>
    <div class="app">
        <PapermillNavbar />
        <PapermillType />
        <ShareModal
            :active="showShareModal"
            @close="$store.commit('hideShareModal')"
        />
    </div>
</template>

<script>
import {mapState} from 'vuex'
import PapermillNavbar from '@/components/PapermillNavbar'
import PapermillType from '@/components/PapermillType.vue'
import ShareModal from '@/components/ShareModal'

export default {
    name: 'App',
    components: {
        PapermillNavbar,
        PapermillType,
        ShareModal
    },
    computed: {
        ...mapState(['profile', 'showShareModal'])
    }
}
</script>

<style lang="scss" scoped>
.app {
    padding-top: calc(#{$navbar-height})
}
</style>
