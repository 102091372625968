<template>
    <div class="document-outline">
        <PapermillButton
            v-if="!expanded"
            class="outline-button"
            icon="fa-light fa-list-tree"
            tooltip="Show outline"
            tooltip-direction="right"
            @click="expanded=true"
        />
        <div
            v-else
            class="document-outline-expanded"
        >
            <div class="outline-header">
                <span
                    class="outline-title"
                    @click="gotoTop"
                ><b>Outline</b></span>
                <PapermillButton
                    class="close-button"
                    icon="fa-light fa-xmark"
                    size="sm"
                    color="black"
                    @click="expanded=false"
                />
            </div>
            <div class="inner-container">
                <div
                    v-if="outline.length === 0"
                    class="empty-msg"
                >
                    No headings or figures in document.
                </div>
                <div
                    v-for="(anchor, index) in outline"
                    :key="anchor.id"
                    class="anchor"
                >
                    <span
                        :style="{ 'padding-left': `${indents[index]}rem`}"
                        class="anchorLink"
                        @click="goto(anchor.id)"
                    >
                        {{ anchor.label }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import PapermillButton from '@/components/PapermillButton'
import {EventBus} from '@/event-bus.js'

export default {
    name: 'DocumentOutline',
    components: {PapermillButton},
    data() {
        return {
            expanded: false
        }
    },
    computed: {
        ...mapState(['outline']),
        indents() {
            if (!this.outline) return []
            const tabSize = 0.2
            let inds = []
            let tab = 0
            for (const anchor of this.outline) {
                if (anchor.type === 'heading') {
                    tab = (anchor.level - 1) * tabSize
                }  // figures at same depth as previous anchor
                inds.push(tab)
            }
            return inds
        }
    },
    methods: {
        gotoTop() {
            const scrollableDivElement = document.getElementsByClassName('pm-content-container')[0]
            scrollableDivElement.scrollTo({top: 0, behavior: 'smooth'})
        },
        goto(id) {
            EventBus.$emit('scrollDocToAnchorId', id)
        }
    }
}
</script>

<style scoped lang="scss">
.document-outline {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0.5rem;
    top: 1rem;
    font-family: 'Lato', sans-serif;
    font-size: 0.6rem;
    background-color: white;
    z-index: $z-float;
    .document-outline-expanded {
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 1rem;
        min-width: 10rem;
        .outline-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .close-button {
                position: absolute;
                right: 0.5rem;
            }
        }
        .inner-container {
            max-height: 75vh;
            overflow-y: auto;
            padding-right: 2rem;
            .empty-msg {
                color: grey;
                font-style: italic;
            }
            .anchorLink, .outline-title {
                cursor: pointer;
                user-select: none;
            }
            .anchorLink:hover, outline-title:hover {
                color: $primary;
            }
            .anchorLink:active, outline-title:active {
                color: darken($primary, 10%);
            }
        }
    }
}
</style>
