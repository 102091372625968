<template>
    <div>
        <div class="placeholders-title">
            Placeholders
        </div>
        <div class="placeholder-field-list">
            <PlaceholderField
                v-for="placeholder in sortedPlaceholders"
                :key="placeholder.name"
                :document="currentDocument"
                :placeholder="placeholder"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import PlaceholderField from '@/components/PlaceholderField'
export default {
    name: 'PlaceholderEditor',
    components: {PlaceholderField},
    computed: {
        sortedPlaceholders() {
            function comparePlaceholders( a, b ) {
                if ( a.name < b.name ){
                    return -1
                }
                if ( a.name > b.name ){
                    return 1
                }
                return 0
            }
            let allPlaceholders =
                Object.entries(this.currentStyle.placeholders).map(function([key, val]){ return {name: key, ...val} })
            return allPlaceholders.sort(comparePlaceholders)
        },
        ...mapState(['currentStyle', 'currentDocument'])
    },
}
</script>

<style scoped lang="scss">
.placeholders-title {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-top: 1rem;
    width: 100%;
    border-bottom: solid 2px black;
    height: 1.2rem;
}
.placeholder-field-list {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 3rem;
    // This is a quick hack. It needs to be replaced with proper use of flexbox throughout the app.
    // 1.2 = placeholders-title (see aboev)
    // 3 = style/placeholder tab bar
    height: calc(100vh - ((2 * #{$navbar-height}) + 1.2rem + 3rem));
}
</style>
