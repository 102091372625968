<template>
    <div
        class="modal"
        :class="{'is-active': active}"
    >
        <div class="modal-background" />
        <div class="modal-card">
            <header class="modal-card-head">
                <span class="icon share-icon is-medium">
                    <font-awesome-icon icon="fa-light fa-share-nodes" />
                </span>
                <div class="modal-card-title">
                    Share Document "{{ currentDocument ? currentDocument.name : '' }}"
                </div>
                <button
                    class="delete"
                    aria-label="close"
                    @click="$emit('close')"
                />
            </header>
            <section class="modal-card-body">
                Allow team members to edit this document.
                <ShareTable
                    v-if="teamMembers && teamMembers.length > 0"
                    class="body-table"
                    :users="teamMembers"
                />
                <div
                    v-else
                    class="no-team-members"
                >
                    No team members found.
                </div>
            </section>
            <footer class="modal-card-foot">
                <button
                    class="button is-primary"
                    @click="$emit('close')"
                >
                    Done
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import ShareTable from '@/components/ShareTable'
export default {
    components: {ShareTable},
    props: ['active'],
    computed: {
        teamMembers() {

            if (!this.profile ||
                !this.profile.defaultTeam ||
                !this.profile.defaultTeam.members ||
                !this.currentDocument) return []

            const captureThis = this

            let team = Object.entries(this.profile.defaultTeam.members).map(function([key, val]) {
                return {
                    id: key,
                    ...val,
                    editor: captureThis.currentDocument.roles[key] === 'editor'
                }
            }
            )

            team = team.filter(member => member.id !== this.profile.id)
            team.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

            return team
        },
        ...mapState(['currentDocument', 'profile'])
    },

}
</script>

<style scoped lang="scss">
.modal {
    .share-icon {
        border-radius: 1000px;
        background-color: $primary;
        color: white;
        margin: 0.5rem;

    }
    .modal-card-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 2rem;
        width: 5rem;
    }
    .modal-card-body {
        padding-left: 2rem;
        .body-table {
            margin-top: 1rem;
        }
        .no-team-members {
            color: grey;
            padding: 1rem 2rem 1rem 2rem;
            border: solid grey 1px;
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }
    }
    .modal-card-foot {
        display: flex;
        justify-content: right;
    }
}
</style>
