import {
    Bookmark,
    convertMillimetersToTwip,
    ExternalHyperlink,
    ImageRun,
    InternalHyperlink,
    Paragraph, ShadingType,
    TextRun, UnderlineType
} from 'docx'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import {app} from '@/main.js'
const PLACEHOLDER_FILENAME = 'placeholder.png'

export function getLocalImage(filename, desiredWidth) {
    let url = urlForLocalFile(filename)
    return getImage(url, desiredWidth)
}

export function urlForLocalFile(filename) {
    return `${window.location.protocol}//${window.location.host}/images/${filename}`
}

// Image definitely expected in pixels:
// https://github.com/dolanmiu/docx/blob/8890e13/src/file/media/media.ts#L6
// Microsoft thinks there are 96 pixels per inch
// source: experimentation, and
// https://docs.microsoft.com/en-us/windows/win32/learnwin32/dpi-and-device-independent-pixels
export function getImage(url, desiredWidthCm) {
    const PIXELS_PER_INCH = 96
    const desiredWidthPixels = desiredWidthCm * (PIXELS_PER_INCH / 2.54)
    return new Promise((resolve) => {
        let xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = () => {
            let blob = xhr.response
            let i = new Image()
            i.onload = function() {
                const originalAspectRatio = this.width / this.height
                const desiredHeightPixels = Math.round(desiredWidthPixels / originalAspectRatio)
                app.$log.debug('Loaded image', url, this.width, 'x', this.height, 'scaling to',
                    desiredWidthPixels, 'x', desiredHeightPixels)
                resolve({
                    blob,
                    width: desiredWidthPixels,
                    height: desiredHeightPixels
                })
            }
            i.src = url
        }
        xhr.open('GET', url)
        xhr.send()
    })
}

export function cmToTwip(cm) {
    return convertMillimetersToTwip(Math.round(cm * 10))
}

// Offset units are EMUs
// EMU = English Metric Unit. One cm is 360000 EMUs
// Perhaps designed to avoid FP errors when converting cm/inches
// https://startbigthinksmall.wordpress.com/2010/01/04/points-inches-and-emus-measuring-units-in-office-open-xml/
export function cmToEMU(cm) {
    return cm * 360000
}


export function makeText(text) {
    function ensureLinkPrefix(textString) {
        if (textString.toUpperCase().startsWith('HTTPS://') || textString.toUpperCase().startsWith('HTTP://')) {
            return textString
        } else {
            return 'https://' + textString
        }
    }
    let textString = text.text
    let marks = []
    if (text.marks) {
        marks = text.marks.map(m => m.type)
    }
    let bold = marks.includes('bold')
    let italics = marks.includes('italic')
    let link = marks.includes('link')
    let code = marks.includes('code')
    if (code) {
        return new TextRun({
            text: textString,
            style: 'inlineCode'
        })
    }
    let markOptions = {bold, italics, link}
    if (marks.includes('underline')) {
        markOptions.underline = {type: UnderlineType.SINGLE}
    }
    let result = new TextRun({
        text: textString,
        ...markOptions
    })
    if (link) {
        return new ExternalHyperlink({
            children: [
                result
            ],
            link: ensureLinkPrefix(textString),
            style: 'Hyperlink'
        })
    } else {
        return result
    }
}

function makeMentionLabel(reference) {
    if (reference.type === 'imageFigure') {
        return `Figure ${reference.figureNumber}`
    } else if (reference.type === 'heading') {
        return `"${reference.title}"`
        // let sectionNumber = reference.sectionNumbers.slice(0, reference.level)
        // let formattedSectionNumber = sectionNumber.slice(0, reference.level).join('.') + '. ';
        // return `Section ${formattedSectionNumber}`
    }
}

export function makeMention(mention, refDict) {
    const dest = mention.attrs.linkDestinationId
    if (dest in refDict) {
        return new InternalHyperlink({
            children: [
                new TextRun({
                    text: makeMentionLabel(refDict[dest]),
                    style: 'Hyperlink',
                }),
            ],
            anchor: dest,
        })
    } else {
        return new TextRun({
            text: '(The target of this reference has been removed)',
            color: '#ff0000'
        })
    }

}

export async function makeFigure(block, figureCounter, doc, textWidth, anchorMap, spacing) {

    figureCounter[0] = figureCounter[0] + 1
    const image = block.content[0]
    const caption = block.content[1].content ? block.content[1].content[0].text : ''
    let url

    // If image has been uploaded use that, otherwise render a placeholder
    if (image.attrs.imgId) {
        let imagePath = `docs/${doc.id}/images/${image.attrs.imgId}`
        let storageRef = firebase.storage().ref()
        let fileRef = storageRef.child(imagePath)
        url = await fileRef.getDownloadURL()
    } else {
        url = urlForLocalFile(PLACEHOLDER_FILENAME)
    }

    const sizeFactor = image.attrs.layoutWidth / 100
    const desiredWidth = textWidth * sizeFactor
    let {blob, width, height} = await getImage(url, desiredWidth)

    return [
        new Paragraph({
            children: [
                new ImageRun({
                    data: blob,
                    transformation: {
                        width,
                        height
                    }
                }),
            ],
            spacing: {
                before: cmToTwip(spacing.before)
            }
        }),
        new Paragraph({
            children: [
                new Bookmark({
                    id: block.attrs.id,
                    children: [
                        new TextRun(`Figure ${figureCounter[0]}: ${caption}`)
                    ],
                }),
            ],
            style: 'Caption',
            spacing: {
                after: cmToTwip(spacing.after)
            }
        })
    ]
}

const lightGray = '#D3D3D3'
const darkGray = '#5A5A5A'
// Content array contains a single text item
export function makeCodeBlock(block, {
    fillColor = lightGray,
    borderColor = Array(4).fill(darkGray),
} = {}) {
    app.$log.debug('Make code block', block)
    let text = ''
    if ('content' in block) {
        text = block.content[0].text
    }
    // Convert separate lines to separate text runs, with a break before each line except the first
    const lines = text.split('\n')
    const firstLine = new TextRun({text: lines[0]})
    const rest = lines.splice(1).map(line=>new TextRun({break:1,text:line}))
    let textRuns = [firstLine, ...rest]
    return [
        new Paragraph({
            children: textRuns,
            shading: {
                type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                color: fillColor,
            },
            border: {
                left: {
                    color: borderColor[0],
                    space: 8,
                    style: 'single',
                    size: 6,
                },
                top: {
                    color: borderColor[1],
                    space: 6,
                    style: 'single',
                    size: 6,
                },
                right: {
                    color: borderColor[2],
                    space: 8,
                    style: 'single',
                    size: 6,
                },
                bottom: {
                    color: borderColor[3],
                    space: 6,
                    style: 'single',
                    size: 6,
                },
            },
            style: 'codeBlock',
        })
    ]
}
