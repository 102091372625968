<template>
    <div class="pmtoolbar2">
        <div class="left">
            <ToolbarButton
                class=""
                icon="fa-light fa-sidebar"
                @click="$store.commit('toggleDocList')"
            />
            <ToolbarButton
                class="pmtoolbar-button"
                icon="fa-light fa-file-circle-plus"
                @click="$store.dispatch('newDocument')"
            />
        </div>
        <div class="middle">
            <div
                v-if="mode !== 'empty'"
                class="tabs"
            >
                <ul>
                    <li
                        :class="{ 'is-active': mode === 'focus' }"
                        @click="$store.commit('setMode', 'focus')"
                    >
                        <div class="tab-with-icon">
                            <span class="icon is-small">
                                <font-awesome-icon icon="fa-light fa-pen" />
                            </span>
                            Focus
                        </div>
                    </li>
                    <li
                        :class="{ 'is-active': mode === 'preview' }"
                        @click="$store.commit('setMode', 'preview')"
                    >
                        <div class="tab-with-icon">
                            <span class="icon is-small">
                                <font-awesome-icon icon="fa-light fa-file-magnifying-glass" />
                            </span>
                            Preview
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div
            v-if="mode !== 'empty'"
            class="right"
        >
            <ToolbarButton
                class="pmtoolbar-button"
                icon="fa-light fa-cloud-arrow-down"
                @click="download"
            />
            <ToolbarButton
                class="pmtoolbar-button"
                icon="fa-light fa-share-nodes"
                @click="$store.commit('showShareModal')"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import ToolbarButton from './ToolbarButton'
import {download} from '@/exporter'

export default {
    components: { ToolbarButton},
    computed: {
        ...mapState(['currentDocument', 'currentStyle', 'editor', 'mode', 'profile'])
    },
    methods: {
        download() {
            let filename = `${this.currentDocument.name || 'Untitled Document'}`
            return download(
                this.editor.getJSON(),
                this.currentDocument,
                filename,
                this.currentStyle
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.pmtoolbar2 {
    width: 100%;
    height: 2.5rem;
    background-color: white;
    box-shadow: 0px 2px 4px 0px #00000026;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: $navbar-height;
    z-index: 10;
    .left, .right {
        display: flex;
        align-items: center;
    }
    .middle {
        display: flex;
        align-items: center;
        ul {
            border: none;
            li {
                height: 100%;
                padding: 0.5rem;
                border-bottom: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-top: solid 4px transparent;
                border-bottom: solid 4px transparent;
                cursor: pointer;
                .tab-with-icon {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 0.8rem;
                    span.icon {
                        margin-left: 0px;
                    }
                }
            }
            li:first-of-type {
                margin-right: 1rem;
            }
            li.is-active {
                border-bottom: solid 4px $primary;
            }
        }
    }
}
</style>
