<template>
    <div class="placeholder-image">
        <div class="file is-boxed">
            <label class="file-label">
                <input
                    class="file-input"
                    type="file"
                    name="placeholderImage"
                    @change="fileChange"
                >
                <img
                    class="placeholder-image-button "
                    :src="src"
                    alt="Current Placeholder Image"
                >
                <span class="upload-overlay">
                    <span class="icon is-large upload-icon">
                        <font-awesome-icon
                            icon="fa-light fa-cloud-arrow-up"
                            size="xl"
                        />
                    </span>
                </span>
            </label>
        </div>
        <progress
            v-if="uploading"
            class="progress upload-progress-bar"
            :value="uploadProgress"
            max="100"
        />
    </div>
</template>
<script>
import {toast} from 'bulma-toast'
import {v4 as uuidv4} from 'uuid'
import {mapState} from 'vuex'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import * as dal from '@/dal'
// Temporary technical debt until we move to new renderer
const IMAGE_PLACEHOLDER_URL =  window.location.protocol + '//' + window.location.host + '/images/placeholder.png'

export default {
    name: 'PlaceholderImage',
    props: {
        document: {
            required: true,
            type: Object,
        },
        placeholder: {
            required: true,
            type: Object,
        }
    },
    data() {
        return {
            uploading: false,
            src: IMAGE_PLACEHOLDER_URL,
            uploadProgress: 0,
        }
    },
    computed: {
        ...mapState(['currentDocument'])
    },
    watch: {
        currentDocument: {
            handler(currentDoc) {
                if (currentDoc?.placeholders
                    && currentDoc?.placeholders[this.placeholder.name]
                    && currentDoc?.placeholders[this.placeholder.name].id) {
                    const imageId = currentDoc.placeholders[this.placeholder.name].id
                    let storageRef = firebase.storage().ref()
                    let fileRef = storageRef.child(`docs/${currentDoc.id}/placeholders/${imageId}`)
                    fileRef.getDownloadURL()
                        .then((url) => {
                            this.src = url
                        })
                }
            },
            immediate: true
        },
    },
    methods: {
        async fileChange(e) {
            const fileInput = e.target
            if (fileInput.files.length > 0) {
                const file = fileInput.files[0]
                if (!file.type.match('image.*')) {
                    toast({
                        message: 'File type is not recognised - not an image file',
                        type: 'is-danger',
                        duration: 3000,
                        position: 'bottom-center'
                    })
                    return true
                }
                this.uploading = true
                this.uploadProgress = 0

                // Load into UI
                const captureThis = this
                let reader = new FileReader()

                // Read file, update image on page
                reader.onload = function() {

                    // Set UI src for immediate effect whilst uploading
                    captureThis.src = reader.result

                    // Separately Load image to get width and height. Then upload.
                    let image = new Image()
                    image.onload = function() {
                        const imgWidth = image.naturalWidth
                        const imgHeight = image.naturalHeight
                        captureThis.uploadImage(captureThis.currentDocument.id, file, imgWidth, imgHeight)
                    }
                    image.src = reader.result
                }

                await reader.readAsDataURL(file)
            }
        },
        // Save image to firebase
        async uploadImage(docId, file, width, height) {
            let imgId = uuidv4()
            let storageRef = firebase.storage().ref()
            let fileRef = storageRef.child(`docs/${docId}/placeholders/${imgId}`)
            let captureThis = this
            try {
                this.uploading = true
                let uploadTask = fileRef.put(file)
                uploadTask.on('state_changed',
                              (snapshot) => {
                                  // Observe state change events such as progress, pause, and resume
                                  captureThis.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                              },
                              (error) => {
                                  toast({
                                      message: `Error uploading ${error}`,
                                      type: 'is-danger',
                                      duration: 3000,
                                      position: 'bottom-center'
                                  })
                              },
                              () => {
                                  // Success
                                  this.uploading = false
                                  // Use tiptap callback to set id
                                  const imgDetails = {id: imgId, width, height}
                                  dal.setPlaceholder(docId, captureThis.placeholder.name, imgDetails)
                              }
                )
            } catch (err) {
                toast({
                    message: 'Failed to upload image ' + err,
                    type: 'is-danger',
                    duration: 3000,
                    position: 'bottom-center'
                })
                this.uploading = false
                return true
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.placeholder-image {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    .placeholder-image-button {
        max-height: 7rem;
    }
    .upload-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .3s ease;
        background-color: $primary;
        &:hover {
            opacity: 0.5;
        }
        .icon {
            color: white;
            font-size: 2rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
            &:hover {
                color: darken(white, 10%);
            }
            &:active {
                color: darken(white, 20%);
            }
        }
    }
}
</style>
