import {centimetre, a4} from './export_utils'

const omanosBlue = '#008dc7'
const omanosGreen = '#95c11f'
const omanosGrey = '#666666'
const omanosCodeBackground = '#D0E8F4'
export const sectionIndent = 1.27 * centimetre

export const pageSize = a4

export const pageMargins = {
    left: 2.54,
    top: 3.81,
    right: 2.54,
    bottom: 3.81
}

export const figureSpacing = {
    before: 0.5,
    after: 0.5
}

export const images = {
    logo: 'omanos_logo.png'
}

export const styles = {
    header: {
        font: 'Source Sans Pro',
        fontSize: 12,
        bold: true
    },
    body: {
        name: 'Body',
        font: 'Source Sans Pro',
        fontSize: 11,
        lineHeight: 1.15,
        alignment: 'left'
    },
    pretitle: {
        name: 'Pretitle',
        font: 'Source Sans Pro',
        fontSize: 15,
        lineHeight: 1.15,
        bold: true,
        color: omanosGreen
    },
    title: {
        name: 'Title',
        font: 'Source Sans Pro',
        fontSize: 26,
        color: omanosBlue,
        margin: [2.5, 0, 0, 10],
        bold: true,
        lineHeight: 1.15,
    },
    heading1: {
        name: 'Heading 1',
        font: 'Source Sans Pro',
        fontSize: 16,
        color: omanosBlue,
        lineHeight: 1.15,
        bold: false,
        margin: [0, 18, 0, 6]
    },
    heading2: {
        name: 'Heading 2',
        font: 'Source Sans Pro',
        fontSize: 14,
        color: omanosBlue,
        margin: [sectionIndent, 18, 0, 6],
        lineHeight: 1.15
    },
    heading3: {
        name: 'Heading 3',
        font: 'Source Sans Pro',
        bold: false,
        fontSize: 14,
        margin: [2 * sectionIndent, 18, 0, 6],
        color: omanosGreen
    },
    heading4: {
        name: 'Heading 4',
        font: 'Source Sans Pro',
        bold: false,
        fontSize: 11,
        margin: [3 * sectionIndent, 18, 0, 6],
        color: omanosGreen
    },
    heading5: {
        name: 'Heading 5',
        font: 'Source Sans Pro',
        bold: false,
        fontSize: 11,
        color: omanosGrey,
        margin: [4 * sectionIndent, 18, 0, 6],
    },
    figure: {
        name: 'Figure',
        font: 'Source Sans pro',
        fontSize: 11,
        color: '#95c11f',
        margin: [0, 0, 0, 14]
    },
    caption: {
        font: 'Source Sans Pro',
        fontSize: 11,
        margin: [0, 0, 0, 15],
        color: omanosGrey,
        alignment: 'center',
    },
    list: {
        font: 'Source Sans Pro',
        lineHeight: 1.15,
        fontSize: 11,
        margin: [0, 0, 0, 15],
        alignment: 'justify'
    },
    codeBlock: {
        font: 'Ubuntu Mono',
        fontSize: 10,
        color: '#404040',  // 19% grey
        lineHeight: 1.25,
        preserveLeadingSpaces: true
    },
    inlineCode: {
        font: 'Ubuntu Mono',
        fontSize: 12,
        background: omanosCodeBackground,
        color: '#404040',  // 19% grey
        preserveLeadingSpaces: true
    },
    footer: {
        font: 'Source Sans Pro',
        fontSize: 9,
        alignment: 'left',
        color: 'grey'
    }
}

export const tableLayouts = {
    codeLayout: {
        hLineColor: () => omanosBlue,  // can also be function
        vLineColor: () => omanosBlue,
        paddingLeft: function() { return 8 },  //i, node
        paddingRight: function() { return 8 },
        paddingTop: function() { return 8 },
        paddingBottom: function() { return 8 }
    }
}

const white = '#ffffff'
export const codeColors = {
    fillColor: white,
    borderColor: Array(4).fill(omanosBlue)
}
