<template>
    <div
        class="sidebar"
        tabindex="0"
        @keydown.esc="close"
    >
        <div class="header">
            <div class="header-title">
                Documents
                <PapermillButton
                    icon="fa-light fa-xmark"
                    size="lg"
                    @click="close"
                />
            </div>
            <SearchBox v-model="searchString" />
            <div
                class="edit"
                @click="editMode = !editMode"
            >
                <span>{{ editMode ? 'Done': 'Edit' }}</span>
            </div>
        </div>
        <div class="docs">
            <div
                v-if="docs.length === 0"
                class="no-doc-msg"
            >
                No documents found.
            </div>
            <DocumentCard
                v-for="doc in docs"
                :key="doc.id"
                class="card"
                :class="{'current-doc': currentDocument && doc.id === currentDocument.id}"
                :doc="doc"
                :edit-mode="editMode"
                @click="openDocument(doc.id)"
                @deleteDocument="deleteDocument(doc.id)"
            />
        </div>
        <ConfirmModal
            v-if="showConfirmDeleteDocModal"
            title="Delete document"
            :message="docToDelete ? `Are you sure you wish to delete the document &quot;${docToDelete.name}&quot;?` : &quot;&quot;"
            subtext="This action cannot be undone."
            confirm-text="Delete Document"
            @close="showConfirmDeleteDocModal=false"
            @cancel="showConfirmDeleteDocModal=false"
            @confirm="deleteDocumentConfirmed"
        />
    </div>
</template>
<script>
import {mapState} from 'vuex'
import ConfirmModal from '@/components/ConfirmModal'
import DocumentCard from '@/components/DocumentCard'
import PapermillButton from '@/components/PapermillButton'
import SearchBox from '@/components/SearchBox'
import {toast} from 'bulma-toast'
import {db} from '@/db'

export default {
    name: 'DocumentSidebar',
    components: {ConfirmModal, DocumentCard, PapermillButton,  SearchBox},
    data() {
        return {
            searchString: '',
            editMode: false,
            docToDelete: undefined,
            showConfirmDeleteDocModal: false,
            sharedDocuments: undefined
        }
    },
    computed: {
        docs() {
            if (!this.sharedDocuments || !this.profile || !this.profile.docs) return []
            // Sort by name then ID to ensure consistent ordering
            function compareDocs(a, b) {
                let res = a.name.localeCompare(b.name)
                if (res === 0) return a.id.localeCompare(b.id)
                return res
            }
            let shared = this.sharedDocuments.map(function(doc) { return {shared: true, ...doc, id: doc.id} })
            let mine = Object.values(this.profile.docs).map(function(doc) {
                return {
                    shared: doc.roles ? Object.values(doc.roles).length > 1 : false, ...doc, id: doc.id
                }
            })
            let allDocs = mine.concat(shared)
            return allDocs
                .filter(doc => doc.name && doc.name.toUpperCase().includes(this.searchString.toUpperCase()))
                .sort(compareDocs)
        },
        ...mapState(['currentDocument', 'profile'])
    },
    watch: {
        profile: {
            immediate: true,
            deep: true,
            handler(newProfile) {
                if (!newProfile) return
                const docs = db.collection('docs')
                this.$bind('sharedDocuments',
                           docs.where(`roles.${this.profile.id}`, '==', 'editor'))
            }
        }
    },
    methods: {
        close() {
            this.editMode = false
            this.$store.commit('toggleDocList')
        },
        openDocument(docId) {
            if (this.currentDocument && docId === this.currentDocument.id) return
            this.$store.dispatch('openDocument', docId)
                .catch((err) => {
                    toast({
                        message: `Error - could not open document: ${err.message}`,
                        type: 'is-danger',
                        duration: 3000,
                        position: 'bottom-center'
                    })
                })
        },
        deleteDocument(docId) {
            this.docToDelete = this.profile.docs[docId]
            this.showConfirmDeleteDocModal = true
        },
        deleteDocumentConfirmed() {
            let captureThis = this
            let name = this.docToDelete.name
            this.$store.dispatch('deleteDocument', {userId: this.profile.id, docId: this.docToDelete.id})
                .then(() => {
                    captureThis.docToDelete = undefined
                    captureThis.showConfirmDeleteDocModal = false
                    toast({
                        message: `Document "${name}" deleted`,
                        type: 'is-danger',
                        duration: 3000,
                        position: 'bottom-center'
                    })
                })
        }
    }
}
</script>

<style scoped lang="scss">
.sidebar {
    height: calc(100vh - 2 * #{$navbar-height});
    border-right: solid 1px lightgray;
    padding: 0;
    display: flex;
    flex-direction: column;
    .header {
        padding: 0.6rem !important;
        display: flex;
        flex-direction: column;
        width: 100%;
        .header-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.5rem;
        }
        button.close {
            border: none;
            color: grey;
        }
        .edit {
            width: 100%;
            font-size: 0.9rem;
            margin-top: 1rem;
            text-align: right;
            color: $primary;
            cursor: pointer;
            :hover {
                color: darken($primary, 10%);
            }
            :active {
                color: darken($primary, 15%);
            }
        }
    }
    .docs {
        border-top: solid 0.5px lightgray;
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        .no-doc-msg {
            margin: 1rem;
            color: lightgray;
        }
    }
}
</style>
