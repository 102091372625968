var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"papermill-type"},[_c('PapermillToolbar'),(_vm.mode === 'empty')?_c('NoDocumentMessage'):_vm._e(),_c('div',{staticClass:"columns is-marginless main-view"},[(_vm.showDocList)?_c('DocumentSidebar',{staticClass:"column is-2"}):_vm._e(),((_vm.mode === 'focus' || _vm.mode === 'preview') && _vm.editor && _vm.editor.provider && _vm.editor.provider.isSynced)?_c('DocumentEditor',{staticClass:"column editor",class:{
                'is-12': _vm.mode === 'focus' && !_vm.showDocList,
                'is-10': _vm.mode === 'focus' && _vm.showDocList,
                'is-6': _vm.mode === 'preview' && !_vm.showDocList && !_vm.showStyleEditor,
                'is-5': _vm.mode === 'preview' && _vm.showDocList && !_vm.showStyleEditor,
                'is-5': _vm.mode === 'preview' && !_vm.showDocList && _vm.showStyleEditor,
                'is-4': _vm.mode === 'preview' && _vm.showDocList && _vm.showStyleEditor,
            }}):_vm._e(),(_vm.mode === 'preview')?_c('DocumentPreview',{staticClass:"column preview",class:{
                'is-6': _vm.mode === 'preview' && !_vm.showDocList && !_vm.showStyleEditor,
                'is-5': _vm.mode === 'preview' && _vm.showDocList && !_vm.showStyleEditor,
                'is-5': _vm.mode === 'preview' && !_vm.showDocList && _vm.showStyleEditor,
                'is-4': _vm.mode === 'preview' && _vm.showDocList && _vm.showStyleEditor,
            },attrs:{"auto":_vm.autoPreview}}):_vm._e(),(_vm.mode === 'preview' && _vm.showStyleEditor)?_c('StyleEditor',{staticClass:"column style-editor is-2"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }