<template>
    <div class="no-doc-msg">
        <p>
            To get started,
            <a @click="$store.dispatch('newDocument')">create a new document</a>
            or use the
            <a @click="$store.commit('toggleDocList')">document list</a>
            to open one.
        </p>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'NoDocumentMessage',
    computed: {
        ...mapState(['showDocList'])
    }
}
</script>

<style lang="scss" scoped>
    .no-doc-msg {
        width: 100%;
        margin-top: 2rem;
        font-family: 'Lato', sans-serif;
        color: grey;
        text-align: center;
        line-height: 2rem;
        position: absolute;
    }
</style>