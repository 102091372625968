<template>
    <div class="preview-buttons">
        <button
            class="button has-tooltip-left"
            :data-tooltip="previewCurrent ? 'Refresh Preview (up-to-date)' : 'Refresh Preview'"
            @click="$emit('refresh')"
        >
            <span class="icon">
                <font-awesome-icon
                    :class="{'fa-spin': animateRefresh, 'disabled': !animateRefresh && previewCurrent}"
                    icon="fa-light fa-arrows-rotate"
                />
            </span>
        </button>
        <div class="sep" />
        <button
            class="button has-tooltip-left"
            data-tooltip="Change Style"
            size="is-small"
            @click="$store.commit('toggleStyleEditor')"
        >
            <span class="icon">
                <font-awesome-icon icon="fa-light fa-paintbrush-fine" />
            </span>
        </button>
    </div>
</template>

<script>
const State = {
    Ready: 'Ready',
    AnimatingMinimum: 'Minimum',
    MinimumDone: 'MinimumDone'
}
export default {
    props: ['previewCurrent', 'refreshing'],
    data() {
        return {
            myState: State.Ready,
            minRefreshPeriod: 4000
        }
    },
    computed: {
        animateRefresh() {
            return [State.AnimatingMinimum, State.MinimumDone].includes(this.myState)
        }
    },
    watch: {
        refreshing: {
            handler(newValue) {
                switch(this.myState) {
                case State.Ready:
                    if (newValue) {
                        this.myState = State.AnimatingMinimum
                        this.startTimer()
                    }
                    break
                case State.AnimatingMinimum:
                    if (newValue) {
                        this.startTimer()
                    }
                    break
                case State.MinimumDone:
                    if (newValue) {
                        this.myState = State.AnimatingMinimum
                        this.startTimer()
                    } else {
                        this.myState = State.Ready
                    }
                }
            }
        }
    },
    methods: {
        startTimer() {
            if (this.animationTimer) {
                clearTimeout(this.animationTimer)
            }
            this.animationTimer = setInterval(() => this.myState = this.refreshing ? State.MinimumDone : State.Ready,
                                              this.minRefreshPeriod)
        }
    }
}
</script>

<style scoped lang="scss">
.preview-buttons {
    position: absolute;
    right: 2rem;
    top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px lightgrey;
    border-radius: 5px;
    box-shadow: 0px 1px 4px 0px #00000040;
    padding: 0.2rem;
    background-color: white;
    z-index: $z-toolbars;
    button {
        border: none;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .sep {
        width: 80%;
        border-bottom: 1px solid lightgray;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
    }
    .disabled {
        color: lightgray;
    }
}
</style>
