// Heading uses Vue component to allow dropdown menu, might want all top level components to be like this
import {VueNodeViewRenderer} from '@tiptap/vue-2'
import { mergeAttributes, Node, textblockTypeInputRule } from '@tiptap/core'
import HeadingBlock from '../components/HeadingBlock'

export default Node.create({

    name: 'heading',
    content: 'inline*',
    group: 'block',
    defining: true,
    draggable: true,
    selectable: true,

    addOptions() {
        return {
            levels: [1, 2, 3, 4, 5, 6],
            HTMLAttributes: {},
        }
    },

    addAttributes() {
        return {
            level: {
                default: 1,
                rendered: false,
            },
            numbered: {
                default: true,
                rendered: true,
                renderHTML: attributes => {
                    return {
                        'data-numbered': attributes.numbered
                    }
                },
            },
        }
    },

    renderHTML({ node, HTMLAttributes }) {
        const hasLevel = this.options.levels.includes(node.attrs.level)
        const level = hasLevel
            ? node.attrs.level
            : this.options.levels[0]
        return [`h${level}`,  mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    parseHTML() {
        return this.options.levels
            .map((level) => ({
                tag: `h${level}`,
                getAttrs(el) {
                    return {
                        level,
                        numbered: el.getAttribute('data-numbered') === 'true'
                    }
                }
            }))
    },

    addNodeView() {
        return VueNodeViewRenderer(HeadingBlock, {
            update: ({ oldNode, newNode, updateProps }) => {
                // See https://github.com/ueberdosis/tiptap/issues/3186
                if (newNode.type.name !== this.name) return false
                // Make sure to redraw node as the vue renderer will not show the updated children
                if (newNode.attrs !== oldNode.attrs) return false
                updateProps()
                return true
            },
        })
    },

    addCommands() {
        return {
            setHeading: attributes => ({ commands }) => {
                if (!this.options.levels.includes(attributes.level)) {
                    return false
                }
                return commands.setNode(this.name, attributes)
            },
            toggleHeading: attributes => ({ commands }) => {
                if (!this.options.levels.includes(attributes.level)) {
                    return false
                }
                return commands.toggleNode(this.name, 'paragraph', attributes)
            },
        }
    },

    addKeyboardShortcuts() {
        return this.options.levels.reduce((items, level) => ({
            ...items,
            ...{
                [`Mod-Alt-${level}`]: () => this.editor.commands.toggleHeading({ level }),
            },
        }), {})
    },

    addInputRules() {
        return this.options.levels.map(level => {
            return textblockTypeInputRule({
                find: new RegExp(`^(#{1,${level}})\\s$`),
                type: this.type,
                getAttributes: {
                    level,
                },
            })
        })
    },

})
