var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-buttons"},[_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"Paragraph"},on:{"click":function($event){_vm.editor.chain().focus().setParagraph().run()}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor &&
                        _vm.editor.isActive('paragraph') &&
                        (!(_vm.editor.isActive('bulletList') || _vm.editor.isActive('orderedList')))
                        ? 'fa-regular fa-paragraph' : 'fa-light fa-paragraph'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"Section Heading"},on:{"click":function($event){_vm.editor && !_vm.editor.isActive('table') && _vm.editor.chain().focus().toggleHeading({ level: 1 }).run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('heading', { level: 1 })
                    ? 'fa-regular fa-h1' : 'fa-light fa-h1'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"Subsection Heading"},on:{"click":function($event){_vm.editor && !_vm.editor.isActive('table') && _vm.editor.chain().focus().toggleHeading({ level: 2 }).run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('heading', { level: 2 }) ? 'fa-regular fa-h2' : 'fa-light fa-h2'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",class:{active: _vm.editor && _vm.editor.isActive('heading', { level: 3 })},attrs:{"data-tooltip":"Subsubsection Heading"},on:{"click":function($event){_vm.editor && !_vm.editor.isActive('table') && _vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('heading', { level: 3 })
                    ? 'fa-regular fa-h3' : 'fa-light fa-h3'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",class:{active: _vm.editor && _vm.editor.isActive('heading', { level: 4 })},attrs:{"data-tooltip":"Depth four Heading"},on:{"click":function($event){_vm.editor && !_vm.editor.isActive('table') && _vm.editor.chain().focus().toggleHeading({ level: 4 }).run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('heading', { level: 4 })
                    ? 'fa-regular fa-h4' : 'fa-light fa-h4'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",class:{active: _vm.editor && _vm.editor.isActive('heading', { level: 5 })},attrs:{"data-tooltip":"Depth five Heading"},on:{"click":function($event){_vm.editor && !_vm.editor.isActive('table') && _vm.editor.chain().focus().toggleHeading({ level: 5 }).run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('heading', { level: 5 })
                    ? 'fa-regular fa-h5' : 'fa-light fa-h5'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"Bullet List"},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('bulletList') ? 'fa-regular fa-list-ul' : 'fa-light fa-list-ul'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"OrderedList"},on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('orderedList') ? 'fa-regular fa-list-ol' : 'fa-light fa-list-ol'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"Figure"},on:{"click":_vm.insertImageFigure}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('imageFigure') ? 'fa-regular fa-image' : 'fa-light fa-image'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"Table"},on:{"click":_vm.insertTableFigure}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('table') ? 'fa-regular fa-table' : 'fa-light fa-table'}})],1)]),_c('div',{staticClass:"sep"}),_c('button',{staticClass:"button has-tooltip-left",attrs:{"data-tooltip":"Code"},on:{"click":function($event){_vm.editor && !_vm.editor.isActive('table') && _vm.editor.chain().focus().toggleCodeBlock().run()}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":_vm.editor && _vm.editor.isActive('codeBlock') ? 'fa-regular fa-code' : 'fa-light fa-code'}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }