<template>
    <!--
        We put the element name as a class so CSS can put different spacing around different blocks e.g h1 vs h3.
        data-node-label is used by CSS to populate the ::after part of the label.
        data-drag-handle is used by TipTap's drag and drop support.
        data-id is used to identify blocks for navigation
        Note that the 'as' tag cannot change at run-time without a hack being applied in VueNodeViewRenderer call
    -->
    <node-view-wrapper
        class="papermill-node"
        :class="blockClasses"
        :data-id="node.attrs.id"
    >
        <div
            class="block-label"
            contenteditable="false"
            draggable="false"
            :data-node-label="tag"
        />
        <div
            class="drag-handle"
            contenteditable="false"
            draggable="true"
            data-drag-handle
        />
        <HeadingMenu
            class="heading-menu"
            :node="node"
            :update-attributes="updateAttributes"
            contenteditable="false"
        />
        <node-view-content
            class="node-content"
            :as="tag"
            v-bind="nodeProps"
        />
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2'
import HeadingMenu from '@/components/HeadingMenu'

export default {
    name: 'HeadingBlock',
    components: {
        HeadingMenu,
        NodeViewContent,
        NodeViewWrapper
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
        updateAttributes: {
            type: Function,
            required: true,
        },
    },
    computed: {
        blockClasses() {
            return {
                [`h${this.node.attrs.level}`]: true,
                numbered: this.node.attrs.numbered
            }
        },
        tag() {
            return `h${this.node.attrs.level}`
        },
        nodeProps() {
            return {
                'data-numbered': this.node.attrs.numbered
            }
        }
    }
}
</script>

<style scoped lang="scss">
.papermill-node {
    display: flex;
    align-items: center;
    .heading-menu {
        display: none;
    }
    &:hover .heading-menu {
        display: inline-flex;
    }
}
</style>

