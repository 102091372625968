<template>
    <div class="modal is-active">
        <div class="modal-background" />
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ title }}
                </p>
                <button
                    class="delete"
                    aria-label="close"
                    @click="$emit('close')"
                />
            </header>
            <section class="modal-card-body">
                <span class="icon has-text-danger is-large">
                    <font-awesome-icon
                        icon="fa-solid fa-circle-exclamation"
                        size="4x"
                    />
                </span>
                <div class="msg-container">
                    <p class="main-msg">
                        {{ message }}
                    </p>
                    <p>{{ subtext }}</p>
                </div>
            </section>
            <footer class="modal-card-foot alert-footer">
                <button
                    class="button is-danger"
                    @click="$emit('confirm')"
                >
                    Confirm
                </button>
                <button
                    class="button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'message', 'subtext']
}
</script>

<style scoped lang="scss">
.modal-card {
    .modal-card-body {
        display: flex;
        align-items: center;
        .icon {
            padding: 2.5rem;
        }
    }
    width: 40rem;
    .msg-container {
        margin-left: 1rem;
    }
    .main-msg {
        margin-bottom: 0.5rem;
    }
    .modal-card-foot {
        justify-content: flex-end;
    }
}
</style>
