import Mention from '@tiptap/extension-mention'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import CrossReference from '@/components/CrossReference'

export default Mention.extend({
    selectable: true,
    addAttributes() {
        return {
            ...this.parent(),
            linkDestinationId: {
                default: ''
            }
        }
    },
    addNodeView() {
        return VueNodeViewRenderer(CrossReference)
    },
})
