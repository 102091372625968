import {a4, centimetre} from './export_utils'

export const craftRed = '#E13F3F'
export const craftCodeBackground = '#F9D8D8'

export const copyrightNotice = '© Craft Prospect Ltd. 2022. All rights reserved. This document contains ' +
    'confidential and proprietary information belonging to Craft Prospect Ltd and/or partners. It must not be ' +
    'copied, distributed or otherwise disclosed to any third party and may not be used for any purpose, except as ' +
    'defined in the contract or confidentiality agreement under which this document has been supplied, or unless ' +
    'prior written authorisation has been obtained from Craft Prospect Ltd.'

export const pageMargins = {
    left: 3.17,
    top: 3.79,
    right: 3.17,
    bottom: 2.54
}

export const figureSpacing = {
    before: 0.5,
    after: 0.5
}

export const images = {
    projectLogo: 'project_logo.png',
    logo: 'craft_logo.png',
}

export const pageSize = a4

export const styles = {
    header: {
        font: 'Open Sans',
        fontSize: 8,
    },
    body: {
        font: 'Open Sans',
        fontSize: 11,
    },
    heading1: {
        font: 'Monserrat',
        fontSize: 20,
        color: craftRed,
        margin: [0, 0.5 * centimetre, 0, 0],
        bold: true,
        lineHeight: 1.15,
        textTransform: 'uppercase'  // inspired by CSS
    },
    heading2: {
        name: 'Heading 2',
        font: 'Monserrat',
        fontSize: 16,
        color: craftRed,
        lineHeight: 1.15,
        bold: true,
        margin: [0, 0.5 * centimetre, 0, 0]
    },
    heading3: {
        name: 'Heading 3',
        font: 'Monserrat',
        fontSize: 14,
        color: craftRed,
        margin: [0, 0.25 * centimetre, 0, 0],
        lineHeight: 1,
        bold: true
    },
    heading4: {
        name: 'Heading 4',
        font: 'Monserrat',
        bold: true,
        fontSize: 12,
        color: craftRed,
        margin: [0, 0.25 * centimetre, 0, 0],
    },
    heading5: {
        name: 'Heading 5',
        font: 'Monserrat',
        bold: true,
        fontSize: 12,
        color: craftRed,
        margin: [0, 0.25 * centimetre, 0, 0],
    },
    figure: {
        name: 'Figure',
        font: 'Source Sans pro',
        fontSize: 11,
        color: craftRed,
        margin: [0, centimetre, 0, 14]
    },
    caption: {
        font: 'Source Sans Pro',
        fontSize: 11,
        margin: [20, 0, 20, 15],
        color: craftRed,
        alignment: 'center',
    },
    list: {
        font: 'Open Sans',
        fontSize: 11,
        alignment: 'justify'
    },
    codeBlock: {
        font: 'Ubuntu Mono',
        fontSize: 10,
        color: '#404040',  // 19% grey
        lineHeight: 1.25,
        preserveLeadingSpaces: true
    },
    inlineCode: {
        font: 'Ubuntu Mono',
        fontSize: 12,
        background: craftCodeBackground,
        color: '#404040',  // 19% grey
        preserveLeadingSpaces: true
    },
    footer: {
        font: 'Source Sans Pro',
        fontSize: 9,
        alignment: 'left',
        color: 'grey'
    },
    'cover-placeholder': {
        font: 'Source Sans Pro',
        fontSize: 9,
        color: 'grey'
    },
    'cover-title-placeholder': {
        font: 'Monserrat',
        fontSize: 28,
        color: 'grey',
        bold: true
    },
    'cover-field-name': {
        alignment: 'right',
        font: 'Source Sans Pro',
        fontSize: 11,
        color: craftRed
    },
    'cover-field-value': {
        alignment: 'left',
        font: 'Source Sans Pro',
        fontSize: 11,
        color: 'grey'
    },
    'copyright': {
        alignment: 'justify',
        font: 'Source Sans Pro',
        fontSize: 9,
        color: 'grey'
    }
}

export const tableLayouts = {
    codeLayout: {
        hLineColor: () => craftRed,  // can also be function
        vLineColor: () => craftRed,
        paddingLeft: function() { return 8 },  //i, node
        paddingRight: function() { return 8 },
        paddingTop: function() { return 8 },
        paddingBottom: function() { return 8 }
    }
}

export const codeColors = {
    fillColor: '#F9D8D8',
    borderColor: Array(4).fill(craftRed)
}
