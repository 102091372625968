import pdfMake from 'pdfmake/build/pdfmake'
import {
    codeColors,
    craftRed,
    figureSpacing,
    headerFromTop,
    images,
    listIndent,
    pageMargins,
    pageSize,
    styles,
    tableLayouts,
    tableSpacing
} from './craft_prospect_small_pdf_config'
import {fonts} from '@/exporter/font_utils'
import {loadImages} from './image_utils'
import {
    centimetre,
    getPlaceholder,
    makeFigure,
    makeMention,
    makeTableFigure,
    makeText,
    marginsToPoints,
    pageSizeToPoints,
    makeCodeBlock,
    pageBreakBefore
} from './export_utils'
import store from '@/store'
import {app} from '@/main'
const textWidth = pageSize.width - (pageMargins.left + pageMargins.right)

// Download doc to client desktop
export async function download(json, doc, filename) {
    let pdfDoc = await createDoc(json, doc)
    return pdfDoc.download(filename)
}

// Create doc and return in base 64 for preview display
export async function docToBase64(json, doc) {
    let pdfDoc = await createDoc(json, doc)
    return new Promise(resolve =>
        pdfDoc.getBase64(data => {
            resolve(data)
        })
    )
}

// Create doc using pdfmake
async function createDoc(json, doc) {
    pdfMake.fonts = fonts()
    const docDefinition = await createDocDefinition(json, doc)
    return pdfMake.createPdf(docDefinition, tableLayouts)  // really should be called createDocGenerator!
}

// Create doc definition - data structure for pdfmake
async function createDocDefinition(json, doc) {
    const imgDict = await loadImages(images)
    const anchorMap = store.getters.anchorMap
    const content = await makeContent(json, doc, imgDict, anchorMap)
    const pageMarginsPoints = marginsToPoints(pageMargins)
    const pageSizePoints = pageSizeToPoints(pageSize)
    return {
        header: () => makeHeader(doc),
        footer: makeFooter,
        content,
        pageSize: pageSizePoints,
        styles,
        pageMargins: pageMarginsPoints,
        images: imgDict,
        defaultStyle: {
            font: 'Open Sans',
            fontSize: 11,
        },
        pageBreakBefore
    }
}

// A doc contains one or more blocks
async function makeContent(json, doc, imgDict, anchorDict) {
    let pdf = []
    let sectionCounter = [0, 0, 0, 0, 0]
    let figureCounter = [0]
    let tableCounter = [0]
    let listDepth = 0
    let frontMatter = makeFrontMatter(doc)
    pdf.push(frontMatter)
    for (let node of json.content) {
        let blockResult = await makeBlock(node,
            {sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth, tableCounter})
        pdf.push(blockResult)
    }
    return pdf
}

function makeFrontMatter(doc) {
    const title = getPlaceholder(doc, 'Document Title')
    const author = getPlaceholder(doc, 'Author')
    const dateString = new Date().toLocaleDateString()
    return  [
        {
            text: title.toUpperCase(),
            style: 'title',
        },
        {
            text: `${author} | ${dateString}`,
            style: 'nameAndDate'
        }
    ]
}
// A block can be: a paragraph, a heading, a bullet list, an ordered list, etc.
async function makeBlock(block, options) {
    app.$log.debug(`Make ${block.type}`, block)
    let { sectionCounter, tableCounter, figureCounter, doc, imgDict, anchorDict, listDepth, tableHeading,
        tableText } = options
    let pdf = []
    switch (block.type) {
    case 'paragraph':
        pdf = makePara(block, sectionCounter, {}, anchorDict, listDepth, tableHeading, tableText)
        break
    case 'heading':
        pdf = makeHeading(block, sectionCounter)
        break
    case 'bulletList':
        pdf = await makeBulletList(block, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth)
        break
    case 'orderedList':
        pdf = await makeOrderedList(block, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth)
        break
    case 'imageFigure':
        pdf = await makeFigure(block, figureCounter, doc, imgDict, textWidth, figureSpacing)
        break
    case 'codeBlock':
        pdf = makeCodeBlock(block, codeColors)
        break
    case 'tableFigure':
        pdf = await makeTableFigure(block, tableCounter, doc, textWidth, tableSpacing, makeBlock, anchorDict,
            tableLayouts.tableLayout)
        break
    default:
        break
    }
    app.$log.debug(`${block.type} result:`, pdf)
    return pdf
}

// A paragraph contains one or more inlines
// A pdfmake para is a simple object with a "text" property that can be a string or a list of strings or a list of
// objects with properties that include "text"
function makePara(para, sectionCounter, inlineStyling, anchorDict, listDepth, tableHeading, tableText) {
    app.$log.debug('Making paragraph', para, ' list depth', listDepth)
    let pdf = ' '
    const margin = (listDepth > 0) ? [listIndent * centimetre * listDepth, 0, 0, 0] : styles.body.margin
    let style = 'body'
    if (tableText) style = 'tableText'
    if (tableHeading) style = 'tableHeadingText'
    // Allow for empty paragraph
    if (para.content) {
        pdf = {
            text: para.content.map((p) => makeInline(p, anchorDict)),
            style
        }
    }
    return [{
        text: pdf,
        style,
        lineHeight: styles.body.lineHeight,
        margin,
        ...inlineStyling
    }]
}

// An inline is a sequence of texts, which may have marks
function makeInline(inline, anchorDict) {
    let pdf = []
    switch (inline.type) {
    case 'text':
        pdf = makeText(inline)
        break
    case 'mention':
        pdf = makeMention(inline, anchorDict)
        break
    default:
        console.log('Unrecognised inline node: ' + inline.type)
        break
    }
    return pdf
}

function makeHeading(heading, sectionCounter) {
    let level = heading.attrs.level
    let sectionPrefix = ''
    if (heading.attrs.numbered) {
        for (let i = 0; i < sectionCounter.length; i++) {
            if (i === level - 1) {
                sectionCounter[i] = sectionCounter[i] + 1
            } else if (i >= level) {
                sectionCounter[i] = 0
            }
        }
        sectionPrefix = sectionCounter.slice(0, level).join('.') + '. '
    }
    let text = heading.content && heading.content.length > 0 ? heading.content[0].text : ''
    if (styles['heading' + heading.attrs.level].capitalisation === 'all_caps') {
        text = text.toUpperCase()
    }
    return  [{
        text: sectionPrefix + text,
        style: 'heading' + level,
        id: heading.attrs.id,
        headlineLevel: level,
        margin: styles[`heading${level}`].margin,
        tocItem: 'mainTOC'
    }]

}

// BulletList contains a sequence of list items
async function makeBulletList(bulletList, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth) {
    const markerType = (listDepth % 2) ? 'circle' : 'bullet'
    let childPromises = bulletList.content.map((item) =>
        makeListItem(item, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth))
    let children = await Promise.all(childPromises)
    return [{
        type: markerType,
        ul: children,
        margin: [0, 0.25*centimetre, 0, 0.25*centimetre],  // left top right bottom
    }]
}

// OrderedList contains a sequence of list items
async function makeOrderedList(orderedList, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth) {
    let childPromises = orderedList.content.map((item) =>
        makeListItem(item, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth))
    let children = await Promise.all(childPromises)
    const listTypes = ['numeric', 'lower-alpha', 'lower-roman']
    let listType = listTypes[listDepth % 3]
    return [{
        ol: children,
        margin: [0, 0.25*centimetre, 0, 0.25*centimetre],  // left top right bottom
        type: listType
    }]
}

// List item contains at least one paragraph then an optional sequence of blocks
async function makeListItem(listItem, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth) {
    const head = listItem.content[0]
    const tail = listItem.content.slice(1)
    let pdfList = makePara(head, sectionCounter, {lineHeight: 1.1}, anchorDict, listDepth+1)
    if (tail.length > 0) {
        let tailPromises = tail.map((block) => makeBlock(block,
            {sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth: listDepth+1}))
        let blocks = await Promise.all(tailPromises)
        pdfList = pdfList.concat(blocks)
    }
    return pdfList
}

function makeHeader(doc) {
    const title = getPlaceholder(doc, 'Document Title')
    const status = getPlaceholder(doc, 'Status').toUpperCase()
    const category = getPlaceholder(doc, 'Category')
    const docNumber = getPlaceholder(doc, 'Doc Number')
    const release = getPlaceholder(doc, 'Release')

    return [
        {
            text: title,
            alignment: 'right',
            margin: [0, (headerFromTop)*centimetre, pageMargins.right * centimetre, 0],
            id: 'header1'  // important to ensure widow prevention code can exclude header nodes
        },
        {
            text: `${status} | ${category} | ${docNumber}-${release}`,
            alignment: 'right',
            margin: [0, 0, pageMargins.right * centimetre, 0],
            id: 'header2'  // important to ensure widow prevention code can exclude header nodes
        },
        {
            image: 'logo',
            width: 3.5 * centimetre,
            absolutePosition: {
                x: pageMargins.left * centimetre,
                y: headerFromTop * centimetre
            },
            id: 'header3'  // important to ensure widow prevention code can exclude header nodes
        },
        {
            canvas: [
                {
                    type: 'line',
                    lineWidth: 1,
                    lineColor: craftRed,
                    x1: 0, y1: 0,
                    x2: (pageSize.width - (pageMargins.left + pageMargins.right)) * centimetre, y2: 0
                },
            ],
            absolutePosition: {
                x: pageMargins.left * centimetre,
                y: ((pageMargins.top-headerFromTop) * centimetre)
            },
            id: 'header4'  // important to ensure widow prevention code can exclude header nodes
        },

    ]
}

function makeFooter(currentPage, totalPages) {

    const dateString = new Date().toLocaleDateString()
    const timeString = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})

    return [
        {
            canvas: [
                {
                    type: 'line',
                    lineWidth: 1.5,
                    lineColor: craftRed,
                    x1: 0, y1: 0,
                    x2: (pageSize.width - (pageMargins.left + pageMargins.right)) * centimetre, y2: 0,
                },

            ],
            margin: [pageMargins.left * centimetre, .75*centimetre, 0, 0.1 * centimetre],
            id: 'footer1'  // important to ensure widow prevention code can exclude header nodes
        },
        {
            // asterisk widths are divided equally
            columns: [
                {text: `Print | ${dateString} | ${timeString}`, width: '*', style: 'footer', id: 'footer2'},
                {text: 'Confidential', width: '*', style: 'footer', alignment: 'center',  id: 'footer3'},
                {text: `${currentPage} | ${totalPages}`, width: '*', style: 'footer', alignment: 'right',
                    id: 'footer4'},
            ],
            margin: [pageMargins.left * centimetre, 0.1*centimetre, pageMargins.right * centimetre, 0],
            id: 'footer5'  // important to ensure widow prevention code can exclude header nodes
        },

    ]
}
