<template>
    <div
        class="style-thumbnail"
        @click="$emit('setSelectStyle', styleId)"
    >
        <img
            :class="{ 'selected': styleSelected }"
            :src="imgPath"
        >
        <figcaption :class="{ 'selected': styleSelected }">
            {{ styleName }}
        </figcaption>             
    </div>      
</template>

<script>


export default {    
    name: 'StyleThumbnail',
    props: ['styleId', 'styleName', 'imgPath', 'styleSelected'],
}
</script>

<style scoped lang="scss">
.style-thumbnail{ 
    padding: 0.5em 0.5em;
        img{
            flex-grow: 3;
            flex-shrink: 3;
            width: 120px;
            height: 160px;
            border: solid 1px lightgrey;
            border-radius: 5px;
            box-shadow: 0px 1px 4px 0px #00000040;
            &.selected{
                border: 1px solid $primary;
            } 
        }
        figcaption{
            font-size: 11px;
            text-align: center;
            &.selected{
                color: $primary;
                font-weight: bold;
            } 
        }
}

</style>