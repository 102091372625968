import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import pdfMake from 'pdfmake/build/pdfmake'
import {codeColors, copyrightNotice, craftRed, figureSpacing, images, pageMargins, pageSize, styles, tableLayouts}
    from './craft_prospect_pdf_config'
import {fonts} from '@/exporter/font_utils'
import {loadImages} from './image_utils'
import {
    centimetre, getPlaceholder,
    makeCodeBlock,
    makeFigure,
    makeMention,
    makeText,
    marginsToPoints,
    pageBreakBefore,
    pageSizeToPoints,
} from './export_utils'
import store from '@/store'

const textWidth = pageSize.width - (pageMargins.left + pageMargins.right)

// Temporary technical debt until we move to new renderer
const DEFAULT_IMAGE_PLACEHOLDER_URL =  window.location.protocol + '//' + window.location.host + '/images/placeholder.png'

// Download doc to client desktop
export async function download(json, doc, filename) {
    let pdfDoc = await createDoc(json, doc)
    return pdfDoc.download(filename)
}

// Create doc and return in base 64 for preview display
export async function docToBase64(json, doc) {
    let pdfDoc = await createDoc(json, doc)
    return new Promise(resolve =>
        pdfDoc.getBase64(data => {
            resolve(data)
        })
    )
}

// Create doc using pdfmake
async function createDoc(json, doc) {
    pdfMake.fonts = fonts()
    const docDefinition = await createDocDefinition(json, doc)
    return pdfMake.createPdf(docDefinition, tableLayouts)  // really should be called createDocGenerator!
}

// Create doc definition - data structure for pdfmake
async function createDocDefinition(json, doc) {
    const imgDict = await loadImages(images)
    const anchorMap = store.getters.anchorMap
    const content = await makeContent(json, doc, imgDict, anchorMap)
    const pageMarginsPoints = marginsToPoints(pageMargins)
    const pageSizePoints = pageSizeToPoints(pageSize)
    return {
        content,
        pageSize: pageSizePoints,
        styles,
        pageMargins: pageMarginsPoints,
        header: (currentPage, _pageCount, _pageSize) => makeHeader(currentPage, doc),
        footer: makeFooter,
        images: imgDict,
        defaultStyle: {
            font: 'Open Sans',
            fontSize: 11,
        },
        pageBreakBefore
    }
}

// A doc contains one or more blocks
async function makeContent(json, doc, imgDict, anchorDict) {
    let pdf = []
    let sectionCounter = [1, 0, 0, 0, 0]
    let figureCounter = [0]
    let listDepth = 0
    pdf.push(await makeCover(doc, anchorDict, imgDict))
    pdf.push(makeExecutiveSummary(doc))
    pdf.push(makeTOC())
    pdf.push(makeIntroduction(doc))
    for (let node of json.content) {
        let blockResult = await makeBlock(node,
            {sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth})
        pdf.push(blockResult)
    }
    return pdf
}

async function makeCover(doc, anchorDict, imgDict) {
    let page = []
    const projectName = getPlaceholder(doc, 'Project Name')
    const documentNumber = getPlaceholder(doc, 'Document Number')
    const release = getPlaceholder(doc, 'Release')
    const documentTitle = getPlaceholder(doc, 'Document Title')
    const customerName = getPlaceholder(doc, 'Customer Name')
    const contractReference = getPlaceholder(doc, 'Contract Reference')
    const authors = getPlaceholder(doc, 'Authors')
    const reviewers = getPlaceholder(doc, 'Reviewers')
    const approver = getPlaceholder(doc, 'Approver')
    const rating = getPlaceholder(doc, 'Rating')
    const todaysDate = new Date().toLocaleDateString()

    page.push({
        image: 'logo',
        width: 6 * centimetre,
    })
    page.push({
        text: `${projectName} | ${documentNumber}-${release}`,
        alignment: 'right',
        style: 'cover-placeholder',
        margins: [0, 2 * centimetre, 0, 0]
    })
    page.push({
        text: `${documentTitle}`,
        alignment: 'right',
        style: 'cover-title-placeholder',
        margins: [0, 0.5*centimetre, 0, 0.3*centimetre]
    })
    page.push({
        canvas: [
            {
                type: 'line',
                x1: 1,
                y1: 1,
                x2: (pageSize.width - (pageMargins.left + pageMargins.right)) * centimetre,
                y2: 1,
                lineColor: craftRed,
            },
        ],
    })
    const projectLogo = getPlaceholder(doc, 'Project Logo')
    let url = DEFAULT_IMAGE_PLACEHOLDER_URL
    if (projectLogo.id) {
        let storageRef = firebase.storage().ref()
        let fileRef = storageRef.child(`docs/${doc.id}/placeholders/${projectLogo.id}`)
        url = await fileRef.getDownloadURL()
    }
    imgDict['projectLogo'] = url

    page.push({
        image: 'projectLogo',
        width: 9.5 * centimetre,
        margin: [0, 0.8*centimetre, 0, centimetre],
        alignment: 'center'
    })
    const colMargins = [0, 0.3*centimetre, 0, 0]
    page.push({
        columns: [
            [
                {text: 'Project', style: 'cover-field-name', width: '*'},
                {text: 'Customer', style: 'cover-field-name', width: '*', margin: colMargins},
                {text: 'Contract', style: 'cover-field-name', width: '*', margin: colMargins},
                {text: 'Authors', style: 'cover-field-name', width: '*', margin: colMargins},
                {text: 'Reviewed by', style: 'cover-field-name', width: '*', margin: colMargins},
                {text: 'Approved by', style: 'cover-field-name', width: '*', margin: colMargins},
                {text: 'Rating', style: 'cover-field-name', width: '*', margin: colMargins},
                {text: 'Release', style: 'cover-field-name', width: '*', margin: colMargins},
                {text: 'Date', style: 'cover-field-name', width: '*', margin: colMargins}
            ],
            [
                {text: `${projectName}`, style: 'cover-field-value', width: '*'},
                {text: `${customerName}`, style: 'cover-field-value', width: '*', margin: colMargins},
                {text: `${contractReference}`, style: 'cover-field-value', width: '*', margin: colMargins},
                {text: `${authors}`, style: 'cover-field-value', width: '*', margin: colMargins},
                {text: `${reviewers}`, style: 'cover-field-value', width: '*', margin: colMargins},
                {text: `${approver}`, style: 'cover-field-value', width: '*', margin: colMargins},
                {text: `${rating}`, style: 'cover-field-value', width: '*', margin: colMargins},
                {text: `${release}`, style: 'cover-field-value', width: '*', margin: colMargins},
                {text: `${todaysDate}`, style: 'cover-field-value', width: '*', margin: colMargins},
            ]
        ],
        columnGap: 0.4 * centimetre,
        margin: [ 0, centimetre, 0, 0],  // LEFT TOP RIGHT BOTTOM
        pageBreak: 'after'
    })
    return page
}

function makeExecutiveSummary(doc) {
    let pdf = []
    const executiveSummary = getPlaceholder(doc, 'Executive Summary')
    pdf.push({
        text: 'EXECUTIVE SUMMARY',
        style: 'heading1',
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: executiveSummary,
        style: 'body',
        pageBreak: 'after'
    })
    return pdf
}

function makeIntroduction(doc) {
    let pdf = []
    const introDocumentScope = getPlaceholder(doc, 'Introduction: Document Scope')
    const introProjectSummary = getPlaceholder(doc, 'Introduction: Project Summary')
    const introVersionControl = getPlaceholder(doc, 'Introduction: Version Control')
    const introListOfVoids = getPlaceholder(doc, 'Introduction: List of Voids')
    const introApplicableDocuments = getPlaceholder(doc, 'Introduction: Applicable Documents')
    const introReferenceDocuments = getPlaceholder(doc, 'Introduction: Reference Documents')
    const introAcronymsAbbrieviations = getPlaceholder(doc, 'Introduction: Acronyms & Abbreviations')
    pdf.push({
        text: '1. INTRODUCTION',
        style: 'heading1',
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: '1.1. Document Scope',
        style: 'heading2',
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: introDocumentScope,
        style: 'body',
        italics: true
    })
    pdf.push({
        text: '1.2. Project Summary',
        style: 'heading2',
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: introProjectSummary,
        style: 'body',
        italics: true
    })
    pdf.push({
        text: '1.3. Version Control',
        style: 'heading2',
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: introVersionControl,
        style: 'body',
        italics: true
    })
    pdf.push({
        text: '1.4. List of Voids',
        style: 'heading2',
        italics: true,
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: introListOfVoids,
        style: 'body',
        italics: true
    })
    pdf.push({
        text: '1.5. Applicable Documents',
        style: 'heading2',
        italics: true,
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: introApplicableDocuments,
        style: 'body',
        italics: true
    })
    pdf.push({
        text: '1.6. Reference Documents',
        style: 'heading2',
        italics: true,
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: introReferenceDocuments,
        style: 'body',
        italics: true
    })
    pdf.push({
        text: '1.7. Acronyms & Abbreviations',
        style: 'heading2',
        italics: true,
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: introAcronymsAbbrieviations,
        style: 'body',
        italics: true
    })
    pdf.push({
        text: '1.8. Copyright Notice',
        style: 'heading2',
        italics: true,
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: 'This document contains confidential and proprietary information belonging to Craft Prospect ' +
            'Ltd and/or partners. It must not be copied, distributed or otherwise disclosed to any third party and ' +
            'may not be used for any purpose, except as defined in the contract or confidentiality agreement under ' +
            'which this document has been supplied, or unless prior written authorisation has been obtained from ' +
            'Craft Prospect Ltd.',
        style: 'body',
        italics: true,
        margin: [0, 0, 0, 0.5*centimetre]
    })
    pdf.push({
        text: 'Any person, other than the authorised holder who finds or otherwise obtains possession of the ' +
            'document should post it together with name and address to: Craft Prospect Ltd, Suite 12, Fairfield, ' +
            '1048 Govan Road, Glasgow G51 4XS. Postage will be refunded.',
        style: 'body',
        italics: true,
        margin: [0, 0, 0, 0.5*centimetre]
    })
    pdf.push({
        text: 'For further information, or to request permission to use this document please ' +
            'email: hello@craftprospect.com.',
        style: 'body',
        italics: true,
        margin: [0, 0, 0, 0.5*centimetre]
    })
    pdf.push({
        text: '1.9. Disclaimer',
        style: 'heading2',
        italics: true,
        tocItem: 'mainTOC'
    })
    pdf.push({
        text: 'Craft Prospect Ltd. does not provide any warranty whatsoever, whether expressed, implied, or ' +
            'statutory, including, but not limited to, any warranty of merchantability or fitness for a particular' +
            ' purpose or any warranty that the contents of the item are error-free. In no respect shall Craft ' +
            'Prospect Ltd. incur any liability for any damages, including, but not limited to, direct, indirect, ' +
            'special, or consequential damages arising out of, resulting from, or in any way connected to the use ' +
            'of this document, whether or not based upon warranty, business agreement, tort, or otherwise; whether ' +
            'or not injury was sustained by persons or property or otherwise; and whether or not loss was sustained ' +
            'from, or arose out of, the results of, the item, or any services that may be provided by Craft Prospect ' +
            'Ltd.',
        style: 'body',
        italics: true,
    })
    return pdf
}

function makeTOC() {
    let pdf = []
    pdf.push({
        toc: {
            id: 'mainTOC',
            title: {text: 'TABLE OF CONTENTS', style: 'heading1'},
        },
        pageBreak: 'after'
    })
    return pdf
}

// A block can be: a paragraph, a heading, a bullet list, an ordered list, etc.
async function makeBlock(block, options) {

    let {sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth} = options
    let pdf = []
    switch (block.type) {
    case 'paragraph':
        pdf = makePara(block, sectionCounter, {}, anchorDict)
        break
    case 'heading':
        pdf = makeHeading(block, sectionCounter)
        break
    case 'bulletList':
        pdf = await makeBulletList(block, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth)
        break
    case 'orderedList':
        pdf = await makeOrderedList(block, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth)
        break
    case 'imageFigure':
        pdf = await makeFigure(block, figureCounter, doc, imgDict, textWidth, figureSpacing)
        break
    case 'codeBlock':
        pdf = makeCodeBlock(block, codeColors)
        break
    default:
        break
    }
    return pdf
}

// A paragraph contains one or more inlines
function makePara(para, sectionCounter, inlineStyling, anchorDict) {
    let pdf = []
    const sectionDepth = sectionCounter[2] > 0 ? 1 : 0
    // Allow for empty paragraph
    if (para.content) {
        pdf = {
            text: para.content.map((p) => makeInline(p, anchorDict)),
            margin: [centimetre * sectionDepth, 0, 0, 0]
        }
    }
    return [{
        text: pdf,
        style: 'body',
        lineHeight: styles.body.lineHeight,
        margin: [0, 0.25*centimetre, 0, 0],
        ...inlineStyling
    }]
}

// An inline is a sequence of texts, which may have marks
function makeInline(inline, anchorDict) {
    let pdf = []
    switch (inline.type) {
    case 'text':
        pdf = makeText(inline)
        break
    case 'mention':
        pdf = makeMention(inline, anchorDict)
        break
    default:
        console.log('Unrecognised inline node: ' + inline.type)
        break
    }
    return pdf
}

function makeHeading(heading, sectionCounter) {
    let level = heading.attrs.level
    let sectionPrefix = ''
    if (heading.attrs.numbered) {
        for (let i = 0; i < sectionCounter.length; i++) {
            if (i === level - 1) {
                sectionCounter[i] = sectionCounter[i] + 1
            } else if (i >= level) {
                sectionCounter[i] = 0
            }
        }
        sectionPrefix = sectionCounter.slice(0, level).join('.') + '. '
    }
    let text = heading.content && heading.content.length > 0 ? heading.content[0].text : ''
    if (styles['heading' + heading.attrs.level].capitalisation === 'all_caps') {
        text = text.toUpperCase()
    }

    let headingPDF = {
        text: sectionPrefix + text,
        style: 'heading' + level,
        id: heading.attrs.id,
        headlineLevel: level,
        margin: styles[`heading${level}`].margin,
        tocItem: 'mainTOC',
    }

    if (level === 1) {
        headingPDF.pageBreak = 'before'
    }

    return [headingPDF]

}

// BulletList contains a sequence of list items
async function makeBulletList(bulletList, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth) {
    const indent = listDepth * 0.1 * centimetre
    const markerType = (sectionCounter[2] > 0) ? 'circle' : 'bullet'
    let childPromises = bulletList.content.map((item) =>
        makeListItem(item, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth))
    let children = await Promise.all(childPromises)
    return [{
        type: markerType,
        ul: children,
        margin: [indent, 0.25*centimetre, 0, 0],  // left top right bottom
    }]
}

// OrderedList contains a sequence of list items
async function makeOrderedList(orderedList, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth) {
    const indent = listDepth * 0.1 * centimetre
    let childPromises = orderedList.content.map((item) =>
        makeListItem(item, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth))
    let children = await Promise.all(childPromises)
    return [{
        ol: children,
        margin: [indent, 0.25*centimetre, 0, centimetre],
    }]
}

// List item contains at least one paragraph then an optional sequence of blocks
async function makeListItem(listItem, sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth) {
    const head = listItem.content[0]
    const tail = listItem.content.slice(1)
    let pdfList = makePara(head, sectionCounter, {lineHeight: 1.15}, anchorDict)
    if (tail) {

        let tailPromises = tail.map((block) => makeBlock(block,
            {sectionCounter, figureCounter, doc, imgDict, anchorDict, listDepth: listDepth+1}))
        let blocks = await Promise.all(tailPromises)
        pdfList = pdfList.concat(blocks)
    }
    return pdfList
}

function makeHeader(pageNumber, doc) {
    if (pageNumber === 1) {return []}
    const category = getPlaceholder(doc, 'Category')
    const projectName = getPlaceholder(doc, 'Project Name')
    const documentNumber = getPlaceholder(doc, 'Document Number')
    const release = getPlaceholder(doc, 'Release')
    return [
        {
            text: category,
            alignment: 'right',
            margin: [0, 1.3*centimetre, pageMargins.right * centimetre, 0],
            style: 'header',
            id: 'header001'  // for widow prevention
        },
        {
            text: projectName,
            alignment: 'right',
            margin: [0, 0, pageMargins.right * centimetre, 0],
            style: 'header',
            id: 'header002'
        },
        {
            text: `${documentNumber}-${release}`,
            alignment: 'right',
            margin: [0, 0, pageMargins.right * centimetre, 0],
            style: 'header',
            id: 'header003'
        },
        {
            image: 'logo',
            width: 3.5 * centimetre,
            absolutePosition: {
                x: pageMargins.left * centimetre,
                y: (pageMargins.top - 2.25) * centimetre
            },
            id: 'header004'
        },
        {
            canvas: [
                {
                    type: 'line',
                    lineWidth: 1.5,
                    lineColor: craftRed,
                    x1: 0, y1: 0,
                    x2: (pageSize.width - (pageMargins.left + pageMargins.right)) * centimetre, y2: 0
                },
            ],
            absolutePosition: {
                x: pageMargins.left * centimetre,
                y: (((pageMargins.top-1) * centimetre))
            },
            id: 'header005'
        },

    ]
}

function makeFooter(currentPage, totalPages) {

    // Cover page has no footer
    if (currentPage === 1) {
        return [
            {
                table: {
                    headerRows: 0,
                    widths: [(pageSize.width - (pageMargins.left + pageMargins.right))* centimetre],
                    body: [
                        [{text: copyrightNotice, style: 'copyright'}]
                    ],
                },
                layout: {
                    hLineWidth: function() {
                        return 1
                    },
                    vLineWidth: function() {
                        return 1
                    },
                    hLineColor: function() {
                        return craftRed
                    },
                    vLineColor: function() {
                        return craftRed
                    },
                    paddingLeft: function() { return 4 },
                    paddingRight: function() { return 4 },
                    paddingTop: function() { return 2 },
                    paddingBottom: function() { return 2 }
                },
                pageBreak: 'after',
                margin: [pageMargins.left * centimetre, -1.5 * centimetre, pageMargins.right * centimetre, 1.8*centimetre]
            }
        ]
    }

    const dateString = new Date().toLocaleDateString()
    const timeString = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})

    return [
        {
            canvas: [
                {
                    type: 'line',
                    lineWidth: 1.5,
                    lineColor: craftRed,
                    x1: 0, y1: 0,
                    x2: (pageSize.width - (pageMargins.left + pageMargins.right)) * centimetre, y2: 0
                },

            ],
            margin: [pageMargins.left * centimetre, 0.75*centimetre, 0, 0],
            id: 'footer001'
        },
        {
            // asterisk widths are divided equally
            columns: [
                {text: `Print | ${dateString} | ${timeString}`, width: '*', style: 'footer', id: 'footer002'},
                {text: 'Confidential', width: '*', style: 'footer', alignment: 'center', id: 'footer003'},
                {text: `${currentPage} | ${totalPages}`, width: '*', style: 'footer', alignment: 'right', id: 'footer004'},
            ],
            margin: [pageMargins.left * centimetre, 0.1*centimetre, pageMargins.right * centimetre, 0],
            id: 'footer005'
        },

    ]
}
