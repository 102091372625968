<template>
    <div class="control has-icons-left search">
        <span class="icon">
            <font-awesome-icon
                icon="fal fa-magnifying-glass"
                size="lg"
            />
        </span>
        <input
            class="input"
            type="text"
            :value="value"
            placeholder="Search documents"
            @keydown.esc.stop="searchString ? searchString = '' : $store.commit('toggleDocList')"
            @input="$emit('input', $event.target.value)"
        >
    </div>
</template>
<script>
export default {
    name: 'SearchBox',
    props: {
        value: {
            type: String
        }
    }
}
</script>
