import Login from '@/components/Login'
import Home from '@/components/Home'
import VueRouter from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    }
]
export const router = new VueRouter({
    routes,
    mode: 'history',
})


export default { router }