// Measurement units: pdfmake uses "points" which 72 points per inch
import {app} from '@/main.js'
import store from '@/store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import {lowlight} from 'lowlight'

export const inch = 72
export const centimetre = (inch / 2.54)
export const numberOfHeadings = 5

export const a4 = {
    height: 29.7,
    width: 21.0
}

export function marginsToPoints(margins) {
    const pageMarginsCentimetres = [margins.left, margins.top, margins.right, margins.bottom]
    return pageMarginsCentimetres.map(margin => margin * centimetre)
}

export function pageSizeToPoints(pageSize) {
    return {
        height: pageSize.height * centimetre,
        width: pageSize.width * centimetre
    }
}

// Section numbers is e.g. [1, 0, 1] for section 1.0.1
// Want to find the depth of current section, starting at depth 1
// Look for last non-zero number in array
export function calcSectionDepth(sectionNumbers) {
    let sectionDepth = 0
    for (let i=0; i<sectionNumbers.length; i++) {
        if (sectionNumbers[i] !== 0) {
            sectionDepth = i
        }
    }
    return sectionDepth
}

export function makeText(text) {
    function ensureLinkPrefix(textString) {
        if (textString.toUpperCase().startsWith('HTTPS://') || textString.toUpperCase().startsWith('HTTP://')) {
            return textString
        } else {
            return 'https://' + textString
        }
    }
    let textString = text.text
    let marks = []
    if (text.marks) {
        marks = text.marks.map(m => m.type)
    }
    let bold = marks.includes('bold')
    let italics = marks.includes('italic')
    let link = marks.includes('link')
    let underline = marks.includes('underline')
    let decoration = underline ? 'underline': ''
    let code = marks.includes('code')
    let result = {
        text: textString,
        bold,
        italics,
        decoration
    }
    if (link) {
        result.link = ensureLinkPrefix(textString)
    }
    if (code) {
        result.style = 'inlineCode'
    }
    return result
}

export function makeMention(mention, refDict) {
    const dest = mention.attrs.linkDestinationId
    let res
    if (dest in refDict) {
        const ref = refDict[dest]
        res = {
            text: makeMentionLabel(ref),
            linkToDestination: mention.attrs.linkDestinationId
        }
    } else {
        res = {
            text: '(The target of this reference has been removed)',
            color: '#ff0000'
        }
    }
    return res
}

function makeMentionLabel(reference) {
    if (reference.type === 'imageFigure') {
        return `Figure ${reference.figureNumber}`
    } else if (reference.type === 'tableFigure') {
        return `Table ${reference.tableNumber}`
    } else if (reference.type === 'heading') {
        if (reference.numbered) {
            let sectionNumber = reference.sectionNumbers.slice(0, reference.level)
            let formattedSectionNumber = sectionNumber.slice(0, reference.level).join('.') + '. '
            return `Section ${formattedSectionNumber}`
        } else {
            return `"${reference.label}"`
        }
    }
}

export async function makeFigure(block, figureCounter, doc, imgDict, textWidth, spacing) {

    figureCounter[0] = figureCounter[0] + 1
    const image = block.content[0]
    const caption = block.content[1].content ? block.content[1].content[0].text : ''

    // If image has been uploaded, insert URL into pdfmake image dictionary
    if (image.attrs.imgId) {
        let imagePath = `docs/${doc.id}/images/${image.attrs.imgId}`
        let storageRef = firebase.storage().ref()
        let fileRef = storageRef.child(imagePath)
        imgDict[image.attrs.imgId] = await fileRef.getDownloadURL()
    }

    // Allow placeholder
    const imageWidth = image.attrs.width ? image.attrs.width : 886
    const imageHeight = image.attrs.height ? image.attrs.height : 392
    const imageId = image.attrs.imgId ? image.attrs.imgId : 'placeholder'

    const sizeFactor = image.attrs.layoutWidth / 100
    const scale = (sizeFactor * textWidth * centimetre) / imageWidth
    const width = imageWidth * scale
    const height = imageHeight * scale

    const formattedImage = {
        image: imageId,
        width,
        height,
        alignment: 'center',
        style: 'figure',
        margin: [0, spacing.before * centimetre, 0, 0]
    }

    return [{
        stack: [
            formattedImage,
            {
                text: 'Figure ' + figureCounter[0] + ': ' + caption,
                style: 'caption',
                margin: [0, 0.25 * centimetre, 0, spacing.after * centimetre],
                id: block.attrs.id  // Click on cross refs doesn't work if we put id in image or containing element
            }
        ],
        unbreakable: true,
    }]
}

export function getPlaceholder(doc, placeholderKey) {
    // Horrendous hack, but it's only for the old renderer to be replaced anyway. Acceptable technical debt.
    const currentStyle = store.getters.currentStyle
    // Default value e.g. for "Document Title" placeholder is "<Document Title>"
    let placeholderVal = `<${placeholderKey}>`
    if (doc.placeholders && doc.placeholders[placeholderKey]) {
        placeholderVal = doc.placeholders[placeholderKey]
    } else if (currentStyle.placeholders
            && currentStyle.placeholders[placeholderKey]
            && currentStyle.placeholders[placeholderKey].default) {
        placeholderVal = currentStyle.placeholders[placeholderKey].default
    }
    return placeholderVal
}

const lightGray = '#D3D3D3'
const darkGray = '#5A5A5A'
// Content array contains a single text item
export function makeCodeBlock(block, {fillColor = lightGray, borderColor = Array(4).fill(darkGray)} = {}) {
    app.$log.debug('Make code block', block)
    const lang = block.attrs.language
    let text = ''
    if ('content' in block) {
        text = block.content[0].text
    }
    let tree
    if (lang) {
        tree = lowlight.highlight(lang, text)
    } else {
        tree = lowlight.highlightAuto(text)
    }
    app.$log.debug('Highlighted html', tree)
    return [{
        layout: 'codeLayout',
        table: {
            headerRows: 0,
            widths: ['100%'],
            body: [
                [{
                    text,
                    style: 'codeBlock',
                    fillColor,
                    borderColor,
                }]
            ]
        },
        margin: [0, .15 * centimetre, 0, 0.15 * centimetre]
    }]
}

export async function makeTableFigure(block, tableCounter, doc, textWidth, spacing, makeBlockCallback, anchorDict,
    tableLayout) {
    app.$log.debug('Make table figure', block)
    tableCounter[0] = tableCounter[0] + 1
    const table = block.content[0]
    const rows = table.content
    const sizeFactor = table.attrs.layoutWidth / 100
    app.$log.debug('Table size factor', sizeFactor)
    const tableMarginLeft = ((1 - sizeFactor) * textWidth * centimetre) / 2
    app.$log.debug('Table margin left', tableMarginLeft)
    const width = sizeFactor * textWidth * centimetre
    const numberOfCols = rows[0].content.length  // only regular tables for now
    const numberOfRows = rows.length
    const colWidth = width / numberOfCols
    const caption = block.content[1].content ? block.content[1].content[0].text : ''
    let body = []
    let rowNumber = 0
    for (let row of rows) {
        let pdfRow = []
        for (let cell of row.content) {
            let cellContents = cell.content
            const options = {
                doc,
                anchorDict,
                listDepth: 0,
                tableHeading: (rowNumber === 0),
                tableText: (rowNumber !== 0)
            }
            let pdfCellPromises = cellContents.map(cell => makeBlockCallback(cell, options))
            let pdfCellContents = await Promise.all(pdfCellPromises)
            app.$log.debug('Cell contents', pdfCellContents)
            pdfRow.push(pdfCellContents)
        }
        body.push(pdfRow)
        rowNumber += 1
    }
    app.$log.debug('table body', body)
    const widths = Array(numberOfCols).fill(colWidth)
    const heights = Array(numberOfRows).fill(10)
    const margin = [0, spacing.before * centimetre, 0, spacing.after*centimetre]
    app.$log.debug('table widths', widths)
    app.$log.debug('table margin', margin)
    const tablePDF = {
        table: {
            headerRows: 1,
            widths,
            heights,
            body,
        },
        layout: tableLayout,

    }
    return [
        {
            columns: [
                {width: '*', text: ''},
                {
                    width: 'auto',
                    stack: [
                        tablePDF,
                        {
                            text: 'Table ' + tableCounter[0] + ': ' + caption,
                            style: 'caption',
                            margin: [0, 0.25 * centimetre, 0, 0],
                            // Click on cross refs doesn't work if we put id in image or containing element
                            id: block.attrs.id
                        }
                    ],
                    unbreakable: true,
                    margin
                },
                {width: '*', text: ''}
            ]
        }

    ]
}


// Standard callback to provide in document definition for pdfmake.
// Prevents section headings becoming widows at the bottom of a page.
// IMPORTANT: all header and footer nodes must be assigned id's beginning with "header" or "footer" for this to work.
// that's because pdfmake passes the header and footer nodes in "followingNodesOnPage" for some reason.
// See https://github.com/bpampuch/pdfmake/issues/1749
export function pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
    const DEBUG_PAGE_BREAKS = false
    // Ignore header and footer nodes, identified by giving them an id beginning header/footer
    function headerOrFooter(node) {
        return node.id && (node.id.startsWith('header') || node.id.startsWith('footer'))
    }
    const followingNodesOnPageFiltered = followingNodesOnPage.filter(node => !headerOrFooter(node))
    if (currentNode.headlineLevel > 0 && DEBUG_PAGE_BREAKS) {
        app.$log.debug('CurrentNode', currentNode)
        app.$log.debug('followingNodesOnPage', followingNodesOnPage)
        app.$log.debug('followingNodesOnPageFiltered', followingNodesOnPageFiltered)
        app.$log.debug('nodesOnNextPage', nodesOnNextPage)
        app.$log.debug('previousNodesOnPage', previousNodesOnPage)
    }
    return currentNode.headlineLevel > 0
        && followingNodesOnPageFiltered.length === 2  // I'm the last node on this page (ignore 2x starting point nodes)
        && nodesOnNextPage.length > 0
}
