<template>
    <div
        class="card doc-card"
        @click="$emit('click')"
    >
        <header class="card-header">
            <p class="card-header-title">
                {{ doc.name }}
            </p>
            <span
                v-if="doc.shared && !editMode"
                class="icon shared"
            >
                <font-awesome-icon icon="fa-light fa-share-nodes" />
            </span>
            <PapermillButton
                v-if="editMode"
                icon="fa-regular fa-trash-can"
                @click="$emit('deleteDocument', $event)"
            />
        </header>
    </div>
</template>
<script>
import PapermillButton from '@/components/PapermillButton'
export default {
    name: 'DocumentCard',
    components: {PapermillButton},
    props: {
        doc: {},
        editMode: {}
    }
}
</script>

<style lang="scss">
.doc-card {
    font-size: 0.9rem;
    box-sizing: content-box;
    border-bottom: solid 0.5px lightgray;
    padding: 0 !important;
    border-radius: 0 !important;
    .card-header {
        width: 100%;
        box-shadow: none !important;
        padding: 0.25rem 1rem 0.25rem 0rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0 !important;
        .card-header-title {
            font-weight: normal !important;
        }
    }
    box-shadow: none !important;
    .shared.icon {
        margin-right: .5rem;
        color: dark-grey;
    }
}
.doc-card.current-doc {
    border-left: solid 6px $primary;
    .card-header-title {
        margin-left: -6px;
    }
}
.card:hover {
    background-color: lighten($primary, 55%);
    cursor: pointer;
}
</style>