import {
    AlignmentType,
    Bookmark,
    Document,
    Footer,
    Header,
    HeadingLevel,
    HorizontalPositionRelativeFrom,
    ImageRun,
    Packer,
    PageBreak,
    PageNumber,
    Paragraph,
    TextRun,
    VerticalPositionRelativeFrom,
} from 'docx'
import {saveAs} from 'file-saver'
const _ = require('lodash')

import {app} from '@/main.js'  // for logging
import store from '@/store' // For quick anchor calculation
import * as OmanosAnalyticsPDF from './omanos_pdf'
import {coverMargins, figureSpacing, numbering, pageMargins, pageSize, sectionIndent, styles}
    from './omanos_word_styles'
import {cmToEMU, cmToTwip, getLocalImage, makeCodeBlock, makeFigure, makeMention, makeText}
    from '@/exporter/docx_utils'
import {codeColors} from '@/exporter/omanos_word_styles'
import {calcSectionDepth, getPlaceholder, numberOfHeadings} from '@/exporter/export_utils'
const textWidth = pageSize.width - (pageMargins.left + pageMargins.right)

// Use the PDF style for preview, at least for now
export async function docToBase64(json, doc) {
    return OmanosAnalyticsPDF.docToBase64(json, doc)
}

// Download Word doc to client desktop
export async function download(json, doc, filename) {
    const fullFilename = `${filename}.docx`
    app.$log.debug('Downloading to file', fullFilename)
    app.$log.debug('JSON Document input: ', json)
    const structure = await createDocumentStructure(json, doc)
    app.$log.debug('Word doc structure', structure)
    const wordDoc = new Document(structure)
    Packer.toBlob(wordDoc).then((blob) => {
        saveAs(blob, fullFilename)
    })
}

// Content of doc. Wrap in a single section for now. Uses docx.js.
async function createDocumentStructure(json, doc) {
    const anchorMap = store.getters.anchorMap
    const coverPage = await makeCover(doc)
    const content = await makeContent(json, {doc, anchorMap})
    const coverMargin = {
        top: cmToTwip(coverMargins.top),
        right: cmToTwip(coverMargins.right),
        bottom: cmToTwip(coverMargins.bottom),
        left: cmToTwip(coverMargins.left),
        header: cmToTwip(coverMargins.header),
        footer: cmToTwip(coverMargins.footer),
        gutter: cmToTwip(coverMargins.gutter),
    }
    const margin = {
        top: cmToTwip(pageMargins.top),
        right: cmToTwip(pageMargins.right),
        bottom: cmToTwip(pageMargins.bottom),
        left: cmToTwip(pageMargins.left),
        header: cmToTwip(pageMargins.header),
        footer: cmToTwip(pageMargins.footer),
        gutter: cmToTwip(pageMargins.gutter),
    }
    return {
        sections: [
            {
                children: coverPage,
                footers: {
                    default: makeCoverFooter()
                },
                properties: {
                    page: {
                        margin: coverMargin
                    }
                }
            },
            {
                headers: {
                    default: await defaultHeader()
                },
                footers: {
                    default: await defaultFooter()
                },
                children: content,
                properties: {
                    page: {
                        margin
                    }
                }
            },
        ],
        numbering,
        styles,
        background: {
            color: 'FFFFFF',
        },
    }
}

// Add a cover page, then iterate through blocks converting each to pdf
async function makeContent(json, options) {
    let {anchorMap, doc} = options
    let content = []
    let sectionCounter = Array(numberOfHeadings).fill(0)
    let figureCounter = [0]
    let listDepth = 0  // we are 1-indexed. Zero means we are not currently in a list. Word is 0 indexed.
    for (let node of json.content) {
        let blockResult = await makeBlock(node, {doc, listDepth, sectionCounter, figureCounter, anchorMap})
        if (blockResult && blockResult.length > 0) {
            content.push(...blockResult)
        }
    }
    return content
}

// Make a cover page - return an array of paragraphs
async function makeCover(doc) {
    let page = []
    const title = getPlaceholder(doc, 'Title')
    const pretitle = getPlaceholder(doc, 'Pretitle').toUpperCase()
    // Logo in top left
    const logoImage = await getLocalImage('omanos_logo.png', 5)
    const circleImage = await getLocalImage('omanos_circle.png', 9)
    const logo = new ImageRun({
        data: logoImage.blob,
        transformation: {
            width: logoImage.width,
            height: logoImage.height,
        },
        floating: {
            horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                offset: cmToEMU(1.71)
            },
            verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                offset: cmToEMU(1.86)
            },
        }
    })
    const circle = new ImageRun({
        data: circleImage.blob,
        transformation: {
            width: circleImage.width,
            height: circleImage.height,
        },
        floating: {
            horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                offset: cmToEMU(-0.6)
            },
            verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                offset: cmToEMU(-3.14)
            },
        }
    })
    const imagePara = new Paragraph({
        children: [
            logo,
            circle
        ]}
    )
    page.push(imagePara)
    page.push(
        new Paragraph({
            text: pretitle.toUpperCase(),
            style: 'pretitle',
            spacing: {
                before: cmToTwip(8)
            }
        })
    )
    page.push(
        new Paragraph({
            text: title,
            heading: HeadingLevel.TITLE,
            spacing: {
                before: cmToTwip(1)
            }
        })
    )
    page.push(
        new Paragraph({
            children: [
                new PageBreak()
            ]
        }
        )
    )
    return page
}

// A block can be: a paragraph, a heading, a bullet list, an ordered list, etc.
async function makeBlock(block, options) {
    app.$log.debug(`Make ${block.type}`, block)
    const {doc, listDepth, sectionCounter, figureCounter, anchorMap} = options
    let docx = []
    switch (block.type) {
    case 'paragraph':
        docx = makePara(block, {}, listDepth, sectionCounter, anchorMap)
        break
    case 'heading':
        docx = makeHeading(block, sectionCounter)
        break
    case 'bulletList':
        docx = await makeBulletList(block, listDepth+1, sectionCounter, anchorMap)
        break
    case 'orderedList':
        docx = await makeOrderedList(block, listDepth+1, sectionCounter, anchorMap)
        break
    case 'imageFigure':
        docx = await makeFigure(block, figureCounter, doc, textWidth, anchorMap, figureSpacing)
        break
    case 'codeBlock':
        docx = makeCodeBlock(block, codeColors)
        break
    default:
        break
    }
    app.$log.debug(`${block.type} result:`, docx)
    return docx
}

// A paragraph contains one or more inlines in its content. Allow for empty paragraphs and additional options.
function makePara(para, options, listDepth, sectionCounter, anchorMap) {
    app.$log.debug('Making a paragraph with options', options, 'and list depth', listDepth)
    let paragraphOptions = {...options}
    let sectionDepth = calcSectionDepth(sectionCounter)
    paragraphOptions.indent = {left: (sectionDepth+listDepth) * sectionIndent}
    if (para.content) {
        paragraphOptions.children = para.content.map((p) => makeInline(p, anchorMap))
    } else {
        paragraphOptions.text = ''
    }
    app.$log.debug('Paragraph created with options', paragraphOptions)
    return [new Paragraph(paragraphOptions)]
}

// An inline is a sequence of texts, which may have marks e.g. mentions
function makeInline(inline, anchorMap) {
    let pdf = []
    switch (inline.type) {
    case 'text':
        pdf = makeText(inline)
        break
    case 'mention':
        pdf = makeMention(inline, anchorMap)
        break
    default:
        app.$log.error('Unrecognised inline node: ' + inline.type)
        break
    }
    return pdf
}

function makeHeading(heading, sectionCounter) {
    let level = heading.attrs.level
    for (let i=0; i < sectionCounter.length; i++) {
        if (i === level-1) {
            sectionCounter[i] = sectionCounter[i] + 1
        } else if (i >= level) {
            sectionCounter[i] = 0
        }
    }
    let text = heading.content && heading.content.length > 0 ? heading.content[0].text : ''
    return [
        new Paragraph({
            heading: HeadingLevel[`HEADING_${level}`],
            children: [
                new Bookmark({
                    id: heading.attrs.id,
                    children: [
                        new TextRun(text)
                    ]
                })
            ]
        })
    ]
}

// Convert lists to a series of paragraphs with correct bullet or numbering added
// Each list item contains "paragraph block*"
// Only render the block if it's a nested list
async function makeBulletList(list, listDepth, sectionCounter, anchorMap) {
    let sectionDepth = calcSectionDepth(sectionCounter)
    let bulletLevel = listDepth+sectionDepth-1
    app.$log.debug('Bullet level', bulletLevel)
    // eslint-disable-next-line no-debugger
    debugger
    const listItemOptions =  {
        bullet: {
            level: bulletLevel  // one extra as word by default aligns with margin
        },
    }
    return makeList(list, listDepth, sectionCounter, anchorMap, listItemOptions)
}

async function makeOrderedList(list, listDepth, sectionCounter, anchorMap) {
    let sectionDepth = calcSectionDepth(sectionCounter)
    const numberingOptions = {
        numbering: {
            reference: 'omanos-numbering',
            level: listDepth+sectionDepth-1
        }
    }
    return makeList(list, listDepth, sectionCounter, anchorMap, numberingOptions)
}

async function makeList(list, listDepth, sectionCounter, anchorMap, listItemOptions) {
    let childPromises = []
    for (let i=0; i < list.content.length; i++) {
        let options = _.cloneDeep(listItemOptions)  // shallow clone will lead to options applying to all items
        options.spacing = {}
        if (i === 0) {
            options.spacing.before = 11 * 20 // TWIPS
        } else if (i === list.content.length - 1) {
            options.spacing.after = 11 * 20
        }
        let childPromise = makeListItem(list.content[i], listDepth, sectionCounter, anchorMap, options)
        childPromises.push(childPromise)
    }
    let children = await Promise.all(childPromises)
    return children.flat()
}

// List item contains "paragraph block*"
// Result: an array of paragraphs
// Currently only accepts nested lists, no other nested blocks
async function makeListItem(listItem, listDepth, sectionCounter, anchorMap, listItemOptions) {
    const paragraph = listItem.content[0]
    const blocks = listItem.content.slice(1)
    let docxPara = makePara(paragraph, listItemOptions, listDepth, sectionCounter, anchorMap)  // returns [paragraph]
    let nestedList = []
    if (blocks && blocks.length > 0 && (blocks[0].type === 'bulletList' || blocks[0].type === 'orderedList')) {
        nestedList = await makeBlock(blocks[0], {listDepth: listDepth, anchorMap, sectionCounter})
    }
    return [...docxPara, ...nestedList]
}

async function defaultHeader() {
    const logo = await getLocalImage('omanos_logo.png', 4)
    const image = new ImageRun({
        data: logo.blob,
        transformation: {
            width: logo.width,
            height: logo.height,
        },
        floating: {
            horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                offset: cmToEMU(1.5)
            },
            verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                offset: cmToEMU(1)
            },
        }
    })
    const imagePara = new Paragraph({children: [image]})
    const pageNumberPara = new Paragraph({
        alignment: AlignmentType.RIGHT,
        style: 'header',
        children: [
            new TextRun({
                children: [PageNumber.CURRENT]
            })
        ]
    })
    return new Header({children: [imagePara, pageNumberPara]})
}

function makeCoverFooter() {
    const dateObj = new Date()
    const monthName = dateObj.toLocaleString('default', { month: 'long' })
    let monthYear = monthName + ' ' + dateObj.getFullYear()
    return new Footer({
        children: [
            new Paragraph({
                text: monthYear.toUpperCase(),
                style: 'Heading4'
            })
        ]
    })
}

async function defaultFooter() {
    const bar = await getLocalImage('omanos_bar_modern2.png', 17)
    const barParagraph = new Paragraph({
        children: [
            new ImageRun({
                data: bar.blob,
                transformation: {
                    width: bar.width,
                    height: bar.height,
                },
            })
        ]
    })
    const envelope = await getLocalImage('omanos_envelope.png', 0.3)
    const cursor = await getLocalImage('omanos_cursor.png', 0.2)
    const twitter = await getLocalImage('omanos_twitter.png', 0.3)
    const linkedin = await getLocalImage('omanos_linkedin.png', 0.3)
    const contents = [
        {icon: envelope, text: ' info@omanosanalytics.org  ', colWidth: 25}, //4.5},
        {icon: cursor, text: ' www.omanosanalytics.org  ', colWidth: 25}, //4.5,
        {icon: twitter, text: ' @OmanosUK  ', colWidth: 15}, //2.5
        {icon: linkedin, text: ' Omanos Analytics', colWidth: 26},  //6.5
    ]
    let items = contents.map(item =>
        [
            new ImageRun({
                data: item.icon.blob,
                transformation: {
                    width: item.icon.width,
                    height: item.icon.height,
                },
            }),
            new TextRun({
                text: item.text,
                style: 'footer'
            })
        ]
    ).flat()

    let paragraphs = [
        barParagraph,
        new Paragraph({children: items, style: 'footer'}),
        new Paragraph({
            text: 'Suite 12, Fairfield, 1048 Govan Road, G51 4XS | Company No. 11084258',
            style: 'footer',
            spacing: {
                before: cmToTwip(0.4)
            }
        }),
    ]
    return new Footer({
        children: paragraphs
    })
}
