<template>
    <button
        class="button"
        @click="$emit('click')"
    >
        <span class="icon">
            <font-awesome-icon
                :icon="icon"
                size="lg"
            />
        </span>
    </button>
</template>

<script>
export default {
    props: ['icon']
}
</script>

<style lang="scss" scoped>
button, button:active, button:focus, button::selection {
    border: none;
    padding: 0.8rem;
    margin-right: 0.1rem;
}
</style>