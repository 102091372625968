<template>
    <div
        ref="pagecontainer"
        :style="pageStyle"
        class="page-container pdfViewer singlePageView"
    />
</template>
<script>
import {PDFPageView, DefaultAnnotationLayerFactory, DefaultTextLayerFactory} from 'pdfjs-dist/web/pdf_viewer'
import * as pdfViewer from 'pdfjs-dist/web/pdf_viewer'

const CSS_UNITS =  (96.0 / 72.0)

export default {
    name: 'PDFPage',
    props: ['page', 'scale'],
    data() {
        return {
            pdfPageView: undefined,
            viewport: undefined,
            renderTask: undefined
        }
    },
    computed: {
        pageStyle() {
            return 'width: '  + this.physicalWidth  + 'px; ' +
                'height: ' + this.physicalHeight + 'px; '
        },

        physicalWidth() {
            return Math.floor(this.logicalWidth * CSS_UNITS)
        },

        physicalHeight() {
            return Math.floor(this.logicalHeight * CSS_UNITS)
        },

        logicalWidth() {
            if (this.viewport) {
                return this.viewport.width
            } else {
                return 500
            }
        },

        logicalHeight() {
            if (this.viewport) {
                return this.viewport.height
            } else {
                return 700
            }
        },

    },
    watch: {
        page() {
            this.pdfPageView.update(this.scale)
            this.updateViewport()
            this.pdfPageView.draw()
        },
        scale() {
            this.pdfPageView.update(this.scale)
            this.updateViewport()
            this.pdfPageView.draw()
        }
    },
    mounted() {
        this.drawPage()
    },
    methods: {
        updateViewport() {
            if (!this.page.dummy) {
                this.viewport = this.page.getViewport({scale: this.scale})
            }
        },
        drawPage() {

            if (this.page.dummy) return

            this.viewport = this.page.getViewport({scale: this.scale})
            const eventBus = new pdfViewer.EventBus()

            this.pdfPageView = new PDFPageView({
                container: this.$refs.pagecontainer,
                id: this.page.pageIndex,
                defaultViewport: this.page.getViewport({ scale: this.scale }),
                scale: this.scale,
                textLayerFactory: new DefaultTextLayerFactory(),
                eventBus,
                annotationLayerFactory: new DefaultAnnotationLayerFactory()
            })

            // Associates the actual page with the view, and draw it
            this.pdfPageView.setPdfPage(this.page)
            this.renderTask = this.pdfPageView.draw()

            this.renderTask.then(() => {

            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../node_modules/pdfjs-dist/web/pdf_viewer.css';

.page-container {
    display: block !important;
    position: relative;
    margin-bottom: 5rem !important;
    margin: auto;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

// Horrendous hack. When overflow-x kicks in, the RHS padding is ignored.
// See:
// https://alexandergottlieb.com/2018/02/22/overflow-scroll-and-the-right-padding-problem-a-css-only-solution/
.page-container:after {
    content: "";
    display: block;
    position: absolute;
    right: -2rem;
    width: 2rem;
    height: 1px;
}
</style>
