<template>
    <div class="style-editor">
        <div class="style-editor-header">
            <div
                v-if="styleTab !== 'empty'"
                class="tabs"
            >
                <ul>
                    <li
                        :class="{ 'is-active': styleTab === 'style' }"
                        @click="setStyleTab('style')"
                    >
                        <a>Style</a>
                    </li>
                    <li
                        :class="{ 'is-active': styleTab === 'placeholder' }"
                        @click="setStyleTab('placeholder')"
                    >
                        <a>Placeholders</a>
                    </li>
                </ul>
            </div>
            <button
                class="button close"
                @click="$store.commit('toggleStyleEditor')"
            >
                <span class="icon">
                    <font-awesome-icon
                        icon="fa-light fa-xmark"
                        size="lg"
                    />
                </span>
            </button>
        </div>
        <StyleThumbnailSelector
            v-if="currentStyle && styleTab==='style'"
            class="style-editor-selector"
        />
        <PlaceholderEditor v-if="displayPlaceholderEditor" />
    </div>
</template>

<script>
import PlaceholderEditor from './PlaceholderEditor.vue'
import StyleThumbnailSelector from './StyleThumbnailSelector.vue'
import {mapState} from 'vuex'

export default {
    name: 'StyleEditor',
    components: {PlaceholderEditor, StyleThumbnailSelector},
    data() {
        return {
            styleTab: undefined
        }
    },
    computed: {
        displayPlaceholderEditor() {
            return this.currentStyle && this.styleTab==='placeholder'
                && this.currentStyle.placeholders
                && Object.keys(this.currentStyle.placeholders).length > 0
        },
        ...mapState(['currentStyle']),
    },
    mounted() {
        this.styleTab = 'style'
    },
    methods: {
        setStyleTab(tab) {
            this.styleTab = tab
        }
    }
}
</script>

<style lang="scss" scoped>
.style-editor {
    height: calc(100vh - 2 * #{$navbar-height});
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0.6rem;
    border-left: 0.5px solid lightgray;
}

button.close {
    border: none;
    color: grey;
}

.style-editor-header {
    font-family: "Lato", sans-serif;
    font-size: 1.1rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
}

.style-editor-selector {
    margin-top: 1rem;
}

.coming-soon-msg {
    font-size: 0.7rem;
    color: grey;
}

.style-selector {
    margin-bottom: 1rem;
}

.read-only-message {
    font-family: "Lato", sans-serif;
    font-size: 0.9rem;
}
div.tabs {
    margin-bottom: 1rem;
}
.tabs li.is-active a {
    border-bottom-width: 3px;
    font-weight: 500;
}

.style-editor-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.style-inner-container {
    overflow: auto;
    flex: 1;
}
</style>
