// This used to get base 64 and do clever things with Promises, but it had a race condition I couldn't figure out
// So now it's stupid URLs
export function loadImages(namesAndFiles) {
    let imgDict = {}
    let namesAndFilesPlusDefaultImages = {
        ...namesAndFiles,
        placeholder: 'placeholder.png'
    }
    Object.entries(namesAndFilesPlusDefaultImages).map(function([id, filename]) {
        imgDict[id] = `${window.location.protocol}//${window.location.host}/images/${filename}`
    })
    return imgDict
}
