import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

import {fonts} from './font_utils'
import {loadImages} from './image_utils'
import {
    calcSectionDepth,
    centimetre,
    getPlaceholder,
    makeFigure,
    makeMention,
    makeText,
    marginsToPoints,
    numberOfHeadings,
    pageBreakBefore,
    pageSizeToPoints,
    makeCodeBlock
}
    from '@/exporter/export_utils'
import {ArrowPointer, Envelope, LinkedIn, Twitter} from './icons'
import store from '@/store' // For quick anchor calculation

import {codeColors, styles, images, pageMargins, pageSize, figureSpacing, sectionIndent,
    tableLayouts} from './omanos_pdf_config'
import {app} from '@/main'

// Handy constants
const textWidth = pageSize.width - (pageMargins.left + pageMargins.right)
const pageWidthPoints = pageSize.width * centimetre

// Download doc to client desktop
export async function download(json, doc, filename) {
    const fullFilename = `${filename}.pdf`
    app.$log.debug('Downloading to file', fullFilename)
    let pdfDoc = await createDoc(json, doc)
    return pdfDoc.download(fullFilename)
}

// Create doc and return in base 64 for preview display
export async function docToBase64(json, doc) {
    let pdfDoc = await createDoc(json, doc)
    return new Promise(resolve =>
        pdfDoc.getBase64(data => {
            resolve(data)
        })
    )
}

// Create doc (generator) using pdfmake
async function createDoc(json, doc) {
    pdfMake.fonts = fonts()
    const docDefinition = await createDocDefinition(json, doc)
    return pdfMake.createPdf(docDefinition, tableLayouts)  // really should be called createDocGenerator!
}

// Create doc definition - data structure for pdfmake
async function createDocDefinition(json, doc) {
    const imgDict = await loadImages(images)
    const anchorMap = store.getters.anchorMap
    const content = await makeContent(json, doc, imgDict, anchorMap)
    const pageMarginsPoints = marginsToPoints(pageMargins)
    const pageSizePoints = pageSizeToPoints(pageSize)
    return {
        content,
        pageSize: pageSizePoints,
        styles,
        pageMargins: pageMarginsPoints,
        header: makeHeader,
        footer: makeFooter,
        images: imgDict,
        defaultStyle: {
            font: 'Open Sans',
            fontSize: 11,
        },
        pageBreakBefore
    }
}

// Add a cover page, then iterate through blocks converting each to pdf
async function makeContent(json, doc, imgDict, anchorDict) {
    let pdf = []
    let sectionCounter = Array(numberOfHeadings).fill(0)
    let figureCounter = [0]
    pdf.push(makeCover(doc))
    for (let node of json.content) {
        let blockResult = await makeBlock(node, {sectionCounter, figureCounter, doc, imgDict, anchorDict})
        pdf.push(blockResult)
    }
    return pdf
}

// Make a cover page
function makeCover(doc) {
    const title = getPlaceholder(doc, 'Title')
    const pretitle = getPlaceholder(doc, 'Pretitle').toUpperCase()
    let page = []
    page.push({
        image: 'logo',
        fit: [125, 125],
        absolutePosition: {x: 40, y: 40},
    })
    page.push({
        canvas: [
            {
                type: 'ellipse',
                x: 1,
                y: 1,
                r1: centimetre * 4.5,
                r2: centimetre * 4.5,
                lineColor: '#008dc7',
            },
        ],
        absolutePosition: {x: 100, y: 35}
    })
    page.push({
        text: pretitle,
        style: 'pretitle',
        margin: [ 0, centimetre*8, 0, 0]  // LEFT TOP RIGHT BOTTOM
    })
    page.push({
        text: title,
        style: 'title',
        margin: [ 0, centimetre, 0, 0],  // LEFT TOP RIGHT BOTTOM
        pageBreak: 'after',
    })
    return page
}

// A block can be: a paragraph, a heading, a bullet list, an ordered list, etc.
async function makeBlock(block, options) {
    app.$log.debug(`Make ${block.type}`, block)
    let {sectionCounter, figureCounter, doc, imgDict, anchorDict} = options
    let pdf = []
    switch (block.type) {
    case 'paragraph':
        pdf = makePara(block, sectionCounter, {}, anchorDict)
        break
    case 'heading':
        pdf = makeHeading(block, sectionCounter)
        break
    case 'bulletList':
        pdf = await makeBulletList(block, sectionCounter, anchorDict)
        break
    case 'orderedList':
        pdf = await makeOrderedList(block, sectionCounter, anchorDict)
        break
    case 'imageFigure':
        pdf = await makeFigure(block, figureCounter, doc, imgDict, textWidth, figureSpacing)
        break
    case 'codeBlock':
        pdf = makeCodeBlock(block, codeColors)
        break
    default:
        break
    }
    app.$log.debug(`${block.type} result:`, pdf)
    return pdf
}

// A paragraph contains one or more inlines
function makePara(para, sectionCounter, inlineStyling, anchorDict) {
    let pdf = []
    const sectionDepth = calcSectionDepth(sectionCounter)
    // Allow for empty paragraph
    if (para.content) {
        pdf = {
            text: para.content.map((p) => makeInline(p, anchorDict)),
            margin: [sectionIndent * sectionDepth, 0, 0, 0]
        }
    }
    return [{
        text: pdf,
        style: 'body',
        lineHeight: styles.body.lineHeight,
        margin: [sectionIndent * sectionDepth, 0.25*centimetre, 0, 0],
        ...inlineStyling
    }]
}

// An inline is a sequence of texts, which may have marks e.g. mentions
function makeInline(inline, anchorDict) {
    let pdf = []
    switch (inline.type) {
    case 'text':
        pdf = makeText(inline)
        break
    case 'mention':
        pdf = makeMention(inline, anchorDict)
        break
    default:
        console.log('Unrecognised inline node: ' + inline.type)
        break
    }
    return pdf
}

function makeHeading(heading, sectionCounter) {
    let level = heading.attrs.level
    for (let i=0; i < sectionCounter.length; i++) {
        if (i === level-1) {
            sectionCounter[i] = sectionCounter[i] + 1
        } else if (i >= level) {
            sectionCounter[i] = 0
        }
    }
    let text = heading.content && heading.content.length > 0 ? heading.content[0].text : ''
    if (styles['heading' + heading.attrs.level].capitalisation === 'all_caps') {
        text = text.toUpperCase()
    }
    return  [{
        text,
        style: 'heading' + level,
        id: heading.attrs.id,
        headlineLevel: level,
    }]

}

// BulletList contains a sequence of list items
async function makeBulletList(bulletList, sectionCounter, anchorDict) {
    let sectionDepth = calcSectionDepth(sectionCounter)
    const markerType = (sectionDepth % 2) ? 'circle' : 'bullet'
    let childPromises = bulletList.content.map((item) =>
        makeListItem(item, sectionCounter, anchorDict))
    let children = await Promise.all(childPromises)
    return [{
        type: markerType,
        ul: children,
        margin: [sectionIndent - (centimetre * 0.425), 0, 0, 0],  // left top right bottom
    }]
}

// OrderedList contains a sequence of list items
async function makeOrderedList(orderedList, sectionCounter, anchorDict) {
    let childPromises = orderedList.content.map((item) =>
        makeListItem(item, sectionCounter, anchorDict))
    let children = await Promise.all(childPromises)
    return [{
        ol: children,
        margin: [sectionIndent - (centimetre * 0.425), 0, 0, 0],  // left top right bottom
    }]
}

// List item contains at least one paragraph then an optional sequence of blocks
async function makeListItem(listItem, sectionCounter, anchorDict) {
    const head = listItem.content[0]
    const tail = listItem.content.slice(1)
    let pdfList = makePara(head, sectionCounter, {lineHeight: 1.15}, anchorDict)
    if (tail) {
        let tailPromises = tail.map((block) => makeBlock(block, {sectionCounter, anchorDict}))
        let blocks = await Promise.all(tailPromises)
        pdfList = pdfList.concat(blocks)
    }
    return pdfList
}

function makeHeader(pageNumber) {
    if (pageNumber === 1) {return []}
    return [
        {
            image: 'logo',
            width: 100,
            alignment: 'left',
            margin: [1.5 * centimetre, centimetre, 0, 0],  // left top right bottom
            id: 'header001'
        },
        {
            text: pageNumber,
            alignment: 'right',
            style: 'heading4',
            margin: [0, 0, 2.5 * centimetre, 0],
            id: 'header002'
        }
    ]
}

function makeFooter(currentPage) {  //, pageCount
    // Cover page has a different footer
    if (currentPage === 1) {
        const dateObj = new Date()
        const monthName = dateObj.toLocaleString('default', { month: 'long' })
        let monthYear = monthName + ' ' + dateObj.getFullYear()
        return [{
            text: monthYear.toUpperCase(),
            style: 'heading4',
            margin: [2.54 * centimetre, 0, 0, 0]
        }]
    }
    return [
        {
            canvas: [
                {
                    type: 'line',
                    x1: 0, y1: centimetre,
                    x2: pageWidthPoints - (centimetre), y2: centimetre,
                    lineWidth: 0.5,
                    lineColor: '#95c11f',

                },
                {
                    type: 'ellipse',
                    x: pageWidthPoints - (centimetre), y: centimetre,
                    r1: centimetre * 0.10,
                    r2: centimetre * 0.10,
                    lineColor: '#95c11f',
                    color: '#95c11f',
                },
            ],
            id: 'footer001'
        },
        {
            columns: [
                {svg: Envelope, width: 8, margin: [0, 0, 0, 0], relativePosition: {x: 0, y: 2}, id: 'footer002'},
                {text: 'info@omanosanalytics.org', width: 'auto', margin: [3, 0, 18, 0], id: 'footer003'},
                {svg: ArrowPointer, width: 6, color: '#008dc7', margin: [0, 0, 0, 0],
                    relativePosition: {x: 0, y: 2}, id: 'footer004'},
                {text: 'www.omanosanalytics.org', width: 'auto', margin: [3, 0, 18, 0], id: 'footer005'},
                {svg: Twitter, width: 8, color: '#008dc7', margin: [0, 0, 0, 0], relativePosition: {x: 0, y: 2},
                    id: 'footer006'},
                {text: '@OmanosUK', margin: [3, 0, 18, 0], width: 'auto', id: 'footer007'},
                {svg: LinkedIn, width: 8, color: '#008dc7', margin: [0, 0, 0, 0], relativePosition: {x: 0, y: 1},
                    id: 'footer008'},
                {text: 'Omanos Analytics', width: 'auto', margin: [3, 0, 0, 0], id: 'footer009'}
            ],
            style: 'footer',
            margin: [
                1.5 * centimetre, 0.5*centimetre, 1.5*centimetre, 0
            ],
            id: 'footer002'
        },
        {
            text: 'Suite 12, Fairfield, 1048 Govan Road, G51 4XS | Company No. 11084258',
            style: 'footer',
            margin: [1.5 * centimetre, 0.25*centimetre, 1.5*centimetre, 0],
            id: 'footer010'
        }
    ]
}
