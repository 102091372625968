<template>
    <button
        class="button"
        :class="tooltipBulmaDirection"
        :data-tooltip="tooltip"
        :style="{color}"
        @click.stop="$emit('click', $event)"
    >
        <span class="icon">
            <font-awesome-icon
                :icon="icon"
                :size="size"
            />
        </span>
    </button>
</template>

<script>
export default {
    name: 'PapermillButton',
    props: {
        color: {
            type: String,
            default: 'grey'
        },
        icon: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: 'lg'
        },
        tooltip: {
            type: String
        },
        tooltipDirection: {
            type: String
        }
    },
    computed: {
        tooltipBulmaDirection() {
            if (!this.tooltipDirection) {
                return ''
            } else {
                return 'has-tooltip-' + this.tooltipDirection
            }
        }
    },
    methods: {
        click(event) {
            this.$emit('click', event)
        }
    }
}
</script>

<style lang="scss" scoped>
.button {
    border: none;
    background: none;
    padding: 1rem;
    :hover {
        color: $primary;
    }
    :active {
        color: darken($primary, 20%)
    }
}
</style>