<template>
    <div class="preview-container">
        <PreviewButtons
            :refreshing="refreshing"
            :preview-current="previewCurrent"
            @refresh="refresh"
        />
        <div class="preview-inner-container">
            <div v-if="src">
                <PDFPage
                    v-for="page in pages"
                    :key="page.pageNumber"
                    :page="page"
                    :scale="scale"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {docToBase64} from '@/exporter'
import PDFPage from './PDFPage.vue'
import _, {isEqual, range} from 'lodash'
import * as pdfjsLib from 'pdfjs-dist/webpack'
import PreviewButtons from '@/components/PreviewButtons'
import {debounce} from '@/utils'
import {EventBus} from '@/event-bus'

export default {
    name: 'DocumentPreview',
    props: ['auto'],
    data() {
        return {
            renderedJSON: {},
            renderedStyle: {},
            renderedDocument: {},
            src: undefined,
            timer: undefined,
            pages: [],
            pdf: undefined,
            scale: 0.7,
            timeout: 5000,
            refreshing: false,
            refreshDebounce: undefined
        }
    },
    computed: {
        ...mapState(['editor', 'currentStyle', 'currentDocument']),
        previewCurrent() {
            if (!this.editor || !this.currentStyle) return true
            let editorJSON = this.editor.getJSON()
            return isEqual(editorJSON, this.renderedJSON) && isEqual(this.currentStyle, this.renderedStyle)
        }
    },
    components: {
        PreviewButtons,
        PDFPage
    },
    watch: {
        currentStyle: {
            handler() {
                this.refresh()
            },
            deep: true
        },
        currentDocument: {
            handler() {
                this.refresh()
            },
            deep: true
        }
    },
    mounted() {
        EventBus.$on('documentChanged', this.refresh)
        this.refresh()  // for HMR
    },
    beforeDestroy() {
        EventBus.$off('documentChanged', this.refresh)
    },
    methods: {
        refresh() {
            if (!this.refreshDebounce) {
                this.refreshDebounce = debounce(() => this.refreshCallback.call(this), 1000)
            }
            this.refreshDebounce()
        },

        refreshCallback() {
            // Don't refresh unless needed
            if (!this.editor || !this.currentStyle) return
            let editorJSON = this.editor.getJSON()
            if (isEqual(editorJSON, this.renderedJSON)
                && isEqual(this.currentStyle, this.renderedStyle)
                && isEqual(this.currentDocument, this.renderedDocument)
            ) {
                return
            }
            // We are refreshing, and want to show animation
            this.refreshing = true
            const captureThis = this
            docToBase64(editorJSON, this.currentDocument, this.currentStyle)
                .then(function(blob) {
                    captureThis.src = 'data:application/pdf;base64,' + blob
                    captureThis.renderedJSON = editorJSON
                    captureThis.renderedStyle = _.cloneDeep(captureThis.currentStyle)
                    captureThis.renderedDocument = _.cloneDeep(captureThis.currentDocument)
                    let loadingTask = pdfjsLib.getDocument(captureThis.src)
                    loadingTask.promise.then(pdf => {
                        captureThis.pdf = pdf
                        let tmpPages = Array(pdf.numPages)
                        tmpPages.fill({dummy: true})
                        captureThis.pages = tmpPages
                        captureThis.refreshing = false
                        // Stream individual pages
                        range(1, pdf.numPages + 1).map(number => pdf.getPage(number).then(
                            page => captureThis.$set(captureThis.pages, number - 1, page)
                        ))
                    })
                }, err => {
                    captureThis.refreshing = false
                    console.error('Error getting base 64: ' + err)
                    console.error(err.stack)
                })
        },
    }
}

</script>

<style scoped lang="scss">
.preview-container {
    background-color: #fbfbfb;
    padding: 0;
    position: relative;
    border-left: 0.5px solid lightgray;
}
.preview-inner-container {
    overflow: auto;
    height: calc(100vh - 2 * #{$navbar-height});
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.preview-inner-container.with-toolbar {
    height: calc(100vh - 2 * #{$navbar-height});
}
.preview-toolbar {
    position: fixed;
    right: 4rem;
    top: 5rem;
    display: flex;
    flex-direction: row;
    margin-right: 0.5rem;
}
.toolbar-button {
    margin-right: 0.5rem;
}
</style>
