import {a4, centimetre} from './export_utils'

export const craftRed = '#E13F3F'
export const craftCodeBackground = '#F9D8D8'

// Unlike Word, our page margins include the header
export const pageMargins = {
    left: 3.17,
    top: 3.79,
    right: 3.17,
    bottom: 3.79
}

export const headerFromTop = 1.25
export const footerFromBottom = 1.25

export const figureSpacing = {
    before: 0.5,
    after: 0.5
}

export const tableSpacing = {
    before: 0.5,
    after: 0.5
}

export const listIndent = 0.63

export const images = {
    projectLogo: 'project_logo.png',
    logo: 'craft_logo.png',
}

export const pageSize = a4

export const styles = {
    header: {
        font: 'Open Sans',
        fontSize: 8,
    },
    body: {
        font: 'Open Sans',
        fontSize: 11,
        margin: [0, 3, 0, 8]
    },
    title: {
        font: 'Monserrat',
        fontSize: 28,
        color: craftRed,
        bold: true,
    },
    nameAndDate: {
        font: 'Open Sans',
        fontSize: 11,
        color: '#757575',
        margin: [0, 3, 0, 8],
    },
    heading1: {
        font: 'Monserrat',
        fontSize: 20,
        color: craftRed,
        margin: [0, 18, 0, 12],
        bold: true,
        // lineHeight: 1.15,
        capitalisation: 'all_caps'  // inspired by CSS
    },
    heading2: {
        name: 'Heading 2',
        font: 'Monserrat',
        fontSize: 16,
        color: craftRed,
        lineHeight: 1.15,
        bold: true,
        margin: [0, 12, 0, 6],  // points
    },
    heading3: {
        name: 'Heading 3',
        font: 'Monserrat',
        fontSize: 14,
        color: craftRed,
        margin: [0, 12, 0, 6],  // points
        lineHeight: 1,
        bold: true,
    },
    heading4: {
        name: 'Heading 4',
        font: 'Monserrat',
        bold: true,
        fontSize: 12,
        color: craftRed,
        margin: [0, 12, 0, 6],  // points
    },
    heading5: {
        name: 'Heading 5',
        font: 'Monserrat',
        bold: true,
        fontSize: 10,
        color: craftRed,
        margin: [0, 14, 0, 10],  // points
        capitalisation: 'all_caps'  // inspired by CSS
    },
    figure: {
        name: 'Figure',
        font: 'Source Sans pro',
        fontSize: 11,
        color: craftRed,
        margin: [0, centimetre, 0, 14]
    },
    caption: {
        font: 'Open Sans',
        fontSize: 10,
        margin: [20, 0, 20, 15],
        alignment: 'center',
        color: '#555555'
    },
    tableHeadingText: {
        font: 'Open Sans',
        fontSize: 9,
        color: '#ffffff',
        bold: true
    },
    tableText: {
        font: 'Open Sans',
        fontSize: 9,
    },
    list: {
        font: 'Open Sans',
        fontSize: 11,
        alignment: 'justify'
    },
    codeBlock: {
        font: 'Ubuntu Mono',
        fontSize: 10,
        color: '#404040',  // 19% grey
        lineHeight: 1.25,
        preserveLeadingSpaces: true
    },
    inlineCode: {
        font: 'Ubuntu Mono',
        fontSize: 12,
        background: craftCodeBackground,
        color: '#404040',  // 19% grey
        preserveLeadingSpaces: true
    },
    footer: {
        font: 'Source Sans Pro',
        fontSize: 9,
        alignment: 'left',
        color: 'grey'
    },
    'cover-placeholder': {
        font: 'Source Sans Pro',
        fontSize: 9,
        color: 'grey'
    },
    'cover-title-placeholder': {
        font: 'Monserrat',
        fontSize: 28,
        color: 'grey',
        bold: true
    },
    'cover-field-name': {
        alignment: 'right',
        font: 'Source Sans Pro',
        fontSize: 11,
        color: craftRed
    },
    'cover-field-value': {
        alignment: 'left',
        font: 'Source Sans Pro',
        fontSize: 11,
        color: 'grey'
    },
    'copyright': {
        alignment: 'justify',
        font: 'Source Sans Pro',
        fontSize: 9,
        color: 'grey'
    }
}

export const tableLayouts = {
    codeLayout: {
        hLineColor: () => craftRed,  // can also be function
        vLineColor: () => craftRed,
        paddingLeft: function() { return 8 },  //i, node
        paddingRight: function() { return 8 },
        paddingTop: function() { return 8 },
        paddingBottom: function() { return 8 }
    },
    tableLayout: {
        hLineWidth: function () { //i, node) {
            return 0.5
            // return (i === 0 || i === node.table.body.length) ? 2 : 1;
        },
        vLineWidth: function () { //i, node) {
            return 0.5
            // return (i === 0 || i === node.table.widths.length) ? 2 : 1;
        },
        hLineColor: function () { //i, node) {
            return craftRed
        },
        vLineColor: function () { //i, node) {
            return craftRed
        },
        // hLineStyle: function (i, node) {
        //     return {dash: {length: 10, space: 4}};
        // },
        // vLineStyle: function (i, node) {
        //     if (i === 0 || i === node.table.widths.length) {
        //         return null;
        //     }
        //     return {dash: {length: 4}};
        // },
        paddingLeft: function() { return 6 },
        paddingRight: function() { return 6 },
        paddingTop: function() { return 0 },
        paddingBottom: function() { return 0 },
        fillColor: function (i) { //}, node) {
            if (i === 0) {
                return craftRed
            } else {
                return '#ffffff'
            }
        }
    }
}

export const codeColors = {
    fillColor: craftCodeBackground,
    borderColor: Array(4).fill(craftRed)
}
