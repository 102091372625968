<template>
    <div class="papermill-type">
        <PapermillToolbar />
        <NoDocumentMessage
            v-if="mode === 'empty'"
        />
        <div class="columns is-marginless main-view">
            <DocumentSidebar
                v-if="showDocList"
                class="column is-2"
            />
            <DocumentEditor
                v-if="(mode === 'focus' || mode === 'preview') && editor && editor.provider && editor.provider.isSynced"
                class="column editor"
                :class="{
                    'is-12': mode === 'focus' && !showDocList,
                    'is-10': mode === 'focus' && showDocList,
                    'is-6': mode === 'preview' && !showDocList && !showStyleEditor,
                    'is-5': mode === 'preview' && showDocList && !showStyleEditor,
                    'is-5': mode === 'preview' && !showDocList && showStyleEditor,
                    'is-4': mode === 'preview' && showDocList && showStyleEditor,
                }
                "
            />
            <DocumentPreview
                v-if="mode === 'preview'"
                class="column preview"
                :auto="autoPreview"
                :class="{
                    'is-6': mode === 'preview' && !showDocList && !showStyleEditor,
                    'is-5': mode === 'preview' && showDocList && !showStyleEditor,
                    'is-5': mode === 'preview' && !showDocList && showStyleEditor,
                    'is-4': mode === 'preview' && showDocList && showStyleEditor,
                }"
            />
            <StyleEditor
                v-if="mode === 'preview' && showStyleEditor"
                class="column style-editor is-2"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

import DocumentEditor from '@/components/DocumentEditor'
import DocumentSidebar from '@/components/DocumentSidebar'
import DocumentPreview from '@/components/DocumentPreview'
import NoDocumentMessage from '@/components/NoDocumentMessage'
import StyleEditor from '@/components/StyleEditor'

import PapermillToolbar from '@/components/PapermillToolbar'

export default {
    name: 'PapermillType',
    components: {DocumentSidebar, DocumentEditor, DocumentPreview, NoDocumentMessage, PapermillToolbar, StyleEditor},
    data() {
        return {
            autoPreview: true,
        }
    },
    beforeDestroy() {
        this.$store.dispatch('closeDocument')
    },
    computed: {
        ...mapState(['currentDocument', 'editor', 'profile', 'mode', 'showDocList', 'showStyleEditor'])
    }
}
</script>

<style lang="scss" scoped>
.main-view {
    margin-top: $navbar-height !important; // important to override bulma is-marginless
}
</style>
