<template>
    <div class="editor-container p-0">
        <DocumentOutline />
        <div class="pm-editor">
            <EditorButtons
                v-if="editor"
                :editor="editor"
            />
            <div class="pm-content-container">
                <FloatingMenu :editor="editor" />
                <EditorContent
                    :editor="editor"
                    class="pm-editor-content"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {EditorContent} from '@tiptap/vue-2'
import {mapState} from 'vuex'
import DocumentOutline from '@/components/DocumentOutline'
import EditorButtons from '@/components/EditorButtons'
import FloatingMenu from '@/components/FloatingMenu'
import {EventBus} from '@/event-bus'

export default {
    name: 'DocumentEditor',
    computed: {
        ...mapState(['editor'])
    },
    components: { DocumentOutline, EditorButtons, EditorContent, FloatingMenu },
    mounted() {
        EventBus.$on('scrollDocToAnchorId', this.scrollToId)
    },
    beforeDestroy() {
        EventBus.$off('scrollDocToAnchorId', this.scrollToId)
    },
    methods: {
        test() {
            this.editor.commands.exitCode()
        },
        scrollToId(id) {
            let el = document.querySelector(`[data-id="${id}"]`)
            let offset = el.offsetTop
            const scrollableDivElement = document.getElementsByClassName('pm-content-container')[0]
            scrollableDivElement.scrollTo({top: offset, behavior: 'smooth'})
        }
    }
}
</script>

<style scoped lang="scss">
// CSS for layout of panes etc.

// Full width of window
.editor-container {
    height: calc(100vh - 2 * #{$navbar-height});
    display: flex;
    justify-content: center;
    overflow: auto;
    position: relative;
}
// Centred within .editor-container
.pm-editor {
    height: calc(100vh - 2 * #{$navbar-height});
    position: relative;
    display: flex;
    justify-content: center;
    width: calc(10rem + #{$editor-width});
    overflow: clip;
}
.pm-content-container {
    display: flex;
    justify-content: center;
    width: calc(#{$editor-width} + 1rem);
    height: calc(100vh - 2 * #{$navbar-height});
    overflow: auto;
    border-left: 1px lightgrey dashed;
    border-right: 1px lightgrey dashed;
    .guidelines {
        position: relative;

        border-left: dashed lightgray 1px;
        border-right: dashed lightgray 1px;
        height: 100vh;
        width: 100%;
    }
}
.pm-editor-content {
    height: fit-content;
    min-height: calc(100vh - 2 * #{$navbar-height});
    padding-bottom: 5rem;
    //padding-right: 4rem;
}
</style>

<style lang="scss">
// Separate styling tag for counters, as they are so counter-intuitive.
// It's crucial to understand that the counters need to be manipulated at the top level divs inside .ProseMirror
// That's why we label the divs as .h1 classes etc.
// Otherwise, css counter inheritance semantics kick in, and AFAICT they just make no sense
.ProseMirror  {
    // Have to explicitly set to zero rather than reset
    // See https://stackoverflow.com/questions/65334209/css-counter-reset-not-working-in-firefox
    // and https://bugzilla.mozilla.org/show_bug.cgi?id=1672569
    counter-set: h1 0 h2 0 h3 0 h4 0 h5 0 fig 0 table 0;
    .h1.numbered {
        counter-reset: h2 h3 h4 h5;
        counter-increment: h1;
    }
    .h2.numbered {
        counter-reset: h3 h4 h5;
        counter-increment: h2;
    }
    .h3.numbered {
        counter-reset: h4 h5;
        counter-increment: h3;
    }
    .h4.numbered {
        counter-reset: h5;
        counter-increment: h4;
    }
    .h5.numbered {
        counter-increment: h5;
    }
    .h1.numbered h1::before {
        padding-right: 0.5rem;
        content: counter(h1);
    }
    .h2.numbered h2::before {
        padding-right: 0.5rem;
        content: counter(h1) '.' counter(h2);
    }
    .h3.numbered h3::before {
        padding-right: 0.5rem;
        content: counter(h1) '.' counter(h2) '.' counter(h3);
    }
    .h4.numbered h4::before {
        padding-right: 0.5rem;
        content: counter(h1) '.' counter(h2) '.' counter(h3) '.' counter(h4);
    }
    .h5.numbered h5::before {
        padding-right: 0.5rem;
        content: counter(h1) '.' counter(h2) '.' counter(h3) '.' counter(h4) '.' counter(h5);
    }
    figure[data-node-type="imageFigure"] {
        counter-increment: fig;
    }
    figure[data-node-type="tableFigure"] {
        counter-increment: table;
    }
}

// Remove borders. Not good for accessibility.
[contenteditable] {
    outline: 0 solid transparent !important;
}

// Show labels and drag handles for top nodes only
.ProseMirror>.papermill-node {

    >.block-label::before {
        display: flex !important;
        vertical-align: top;
        padding-top: 0.3rem;
        text-align: right;
        content: attr(data-node-label);
        width: 2rem;
        text-transform: uppercase;
        color: lightgray;
        font-size: 0.6rem;
    }

    >.drag-handle {
        display: flex !important;
        min-width: 2rem;
    }

}

.ProseMirror>.papermill-node>.node-content {
    border: dotted 1px white;
}

.ProseMirror>.papermill-node:hover>.node-content {
    border: dotted 1px lightgrey;
}

.ProseMirror {
    background-color: transparent;
    border-radius: 0;
    width: calc(#{$editor-width} - 5rem);
    font-family: "Lato", sans-serif;
    height: fit-content;
    padding: 2rem 0;
    margin-right: 2rem; // this balances out the drag handle etc on LHS

    .papermill-node {
        display: flex;
        width: 100%;
        flex: 1;
        .node-content {
            width: 30rem;
            overflow-wrap: break-word;
        }
        p {
            overflow-wrap: break-word;
            // It is ESSENTIAL white-space is pre-wrap, due to a bug in ProseMirror.
            // See: https://github.com/cultureamp/kaizen-design-system/pull/2774
            white-space: pre-wrap;
            max-width: calc(#{$editor-width} - 5rem);
        }
        .drag-handle {
            width: 2rem;
            height: 1.5rem;
            user-select: none;
            cursor: grab;
            background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
            background-repeat: no-repeat;
            background-size: 1rem 1rem;
            background-position: center;
            opacity: 0;
            padding: 0.3rem;
        }
    }
    .papermill-node:hover {
        .drag-handle {
            opacity: 1;
        }
    }
    .figure:hover .delete-button {
        display: inline-flex;
    }
}

.ProseMirror {

    .ProseMirror-gapcursor {
        padding-left: 4rem;
    }

    .mention {
        background: rgba(black, 0.1);
        color: rgba(black, 0.6);
        font-size: 0.8rem;
        border-radius: 5px;
        padding: 0.2rem 0.5rem;
        white-space: nowrap;
    }

    .papermill-node figure.node-content {
        padding: 0.5rem;
        width: 100%;
    }

    figure.has-focus {
        border: solid 1px grey;
    }

    figure[data-node-type="imageFigure"] {
        figcaption::before {
            content: 'Fig ';
        }
        .figure-caption-prefix::after {
            content: counter(fig) ":";
        }
    }

    figure[data-node-type="tableFigure"] {
        figcaption::before {
            content: 'Table ';
        }
        .figure-caption-prefix::after {
            content: counter(table) ":";
        }
    }

    figcaption.is-empty::after {
        content: attr(data-placeholder);
        color: #adb5bd;
        pointer-events: none;
        height: 0;
        float: left;
    }

    figcaption {

        .figure-caption-prefix {
            user-select: none !important;
            margin-right: .5rem;
        }



        .caption {
            white-space: pre-wrap;
            width: auto;
        }

        margin-top: .5rem;
        text-align: left;
        padding: 0.5rem;
        border: solid lightgray 1px !important;
        border-radius: 3px;
        // essential to show cursor, see
        // https://stackoverflow.com/questions/25897883/edit-cursor-not-displayed-on-chrome-in-contenteditable
        display: flex;
        align-items: flex-start;
        width: 100%;

        font-family: Lato, sans-serif;
        font-size: 0.8rem;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        img {
            display: block;
            height: auto;
            border-radius: 0.5rem;
        }

    }

}


.ProseMirror .headingContainer {
    display: flex;  // to get blocktype to be at TL and overlapping
    flex-direction: row;
}

.ProseMirror .blockType {
    width: 2rem;
}

div img.pmimg {
    border: dashed 1px lightgray;
}

div.has-focus img.pmimg {
    border-radius: 3px;
    border: dashed 1px #0f0938;
}

// Block formatting
.ProseMirror {

    font-family: "Lato", sans-serif;

    >.papermill-node.p {
        line-height: 1.5rem !important;
        color: #0e0e0e;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        min-width: 1px;
    }

    >.papermill-node.h1 {
        margin-top: 0.5rem;
    }

    >.papermill-node.h2 {
        margin-top: 0.5rem;
    }

    >.papermill-node.h3 {
        margin-top: 0.5rem;
    }

    >.papermill-node.ul {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }

    >.papermill-node.ol {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }

    h1 {
        font-size: 1.75rem;
        line-height: 2.2rem;
        color: #0e0e0e;
    }

    h2 {
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: #0e0e0e;
        font-weight: normal;
    }

    h3 {
        font-size: 1.1rem;
        line-height: 1.25rem;
        color: #0e0e0e;
    }

}

    .ProseMirror ul  {
        list-style: disc outside;
        margin-left: 1.5rem;

    }

    .ProseMirror ol {
        list-style: decimal;
        margin-left: 1.5rem;

    }

    .ProseMirror>.papermill-node.p.is-empty:first-child:only-child .node-content::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
    }

    .ProseMirror {


        /* Give a remote user a caret */
        .collaboration-cursor__caret {
            position: relative;
            margin-left: -1px;
            margin-right: -1px;
            border-left: 1px solid #0D0D0D;
            border-right: 1px solid #0D0D0D;
            word-break: normal;
            pointer-events: none;
        }

        /* Render the username above the caret */
        .collaboration-cursor__label {
            position: absolute;
            top: -1.4em;
            left: -1px;
            font-size: 10px;
            font-style: normal;
            font-weight: normal;
            line-height: normal;
            user-select: none;
            color: #0D0D0D;
            padding: 0.1rem 0.3rem;
            border-radius: 3px 3px 3px 0;
            white-space: nowrap;
        }
    }

</style>

<style lang="scss">
// Code highlighting - inline code
.ProseMirror {
    code.inline-code {
        padding: 0.25em;
        border-radius: 0.25em;
        background-color: rgba(#616161, 0.1);
        color: #616161;
        box-decoration-break: clone;
        vertical-align: 1px;
    }
}

// Code highlighting - code blocks
.ProseMirror {

    .papermill-node.code.top-node {
        padding: 8px;
        margin-right: 2rem;
    }

    .papermill-node.code pre {

        background: whitesmoke;
        color: #4d4d4c;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border: solid black 1px;
        border-radius: 0.5rem;
        width: 100%;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }

        .hljs-comment,
        .hljs-quote {
            color: #8e908c;
        }

        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class {
            color: #c82829;
        }

        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
            color: #f5871f;
        }

        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
            color: #718c00;
        }

        .hljs-title,
        .hljs-section {
            color: #4271ae;
        }

        .hljs-keyword,
        .hljs-selector-tag {
            color: #8959a8;
        }

        .hljs-emphasis {
            font-style: italic;
        }

        .hljs-strong {
            font-weight: 700;
        }
    }
}
</style>

<style lang="scss">
.ProseMirror {
    table {
        border-collapse: collapse;
        margin: 0;
        overflow: hidden;
        table-layout: fixed;
        width: 100%;
        font-size: 0.8rem;

        tbody tr:last-child td, .table tbody tr:last-child th {
            border-width: 2px;
        }

        td,
        th {
            border: 2px solid #ced4da;
            box-sizing: border-box;
            min-width: 1em;
            padding: 3px 5px;
            position: relative;
            vertical-align: top;

            > * {
                margin-bottom: 0;
            }
        }

        // as previously th
        :first-child td {
            background-color: #f1f3f5;
            font-weight: bold;
            text-align: left;
        }

        .selectedCell:after {
            background: rgba(200, 200, 255, 0.4);
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            position: absolute;
            z-index: 2;
        }

        .column-resize-handle {
            background-color: #adf;
            bottom: -2px;
            position: absolute;
            right: -2px;
            pointer-events: none;
            top: 0;
            width: 4px;
        }

        p {
            margin: 0;
        }
    }
}

.tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
}

.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}
</style>
