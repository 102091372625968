import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import VueTippy, { TippyComponent } from 'vue-tippy'

import VueNumericInput from 'vue-numeric-input'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faCircleChevronDown} from '@fortawesome/pro-solid-svg-icons'
import {
    faBold,
    faCircleDown,
    faCircleUser,
    faCode as faCodeRegular,
    faEye,
    faFolderOpen,
    faH1 as faH1Regular,
    faH2 as faH2Regular,
    faH3 as faH3Regular,
    faH4 as faH4Regular,
    faH5 as faH5Regular,
    faImage as faImageRegular,
    faItalic,
    faListOl as faListOlRegular,
    faListUl as faListUlRegular,
    faParagraph as regularFaParagraph,
    faPenToSquare,
    faStrikethrough,
    faTable as faTableRegular,
    faTrashCan,
    faUnderline
}
    from '@fortawesome/pro-regular-svg-icons'
import { faAngleDown, faArrowRightFromBracket, faBrush, faCircleExclamation,
    faPlus } from '@fortawesome/pro-solid-svg-icons'

import {
    faArrowsRotate,
    faCode,
    faCloudArrowDown,
    faCloudArrowUp,
    faEllipsisVertical,
    faEnvelope,
    faFileCirclePlus,
    faFileMagnifyingGlass,
    faGripVertical,
    faH1,
    faH2,
    faH3,
    faH4,
    faH5,
    faImage,
    faListOl,
    faListTree,
    faListUl,
    faLock,
    faMagnifyingGlass,
    faPaintBrushFine,
    faParagraph,
    faPen,
    faShareNodes,
    faSidebar,
    faTable,
    faXmark
} from '@fortawesome/pro-light-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueLogger from 'vuejs-logger'
const isProduction = process.env.NODE_ENV === 'production'
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
}
Vue.use(VueLogger, options)

import App from './App.vue'
import store from '@/store/'

// Bulma and customisations
require('@/assets/main.scss')

// Font Awesome
library.add(faAngleDown,
    faArrowRightFromBracket,
    faArrowsRotate,
    faBold,
    faBrush,
    faCircleChevronDown,
    faCircleDown,
    faCircleExclamation,
    faCircleUser,
    faCloudArrowDown,
    faCloudArrowUp,
    faCode,
    faCodeRegular,
    faEllipsisVertical,
    faEnvelope,
    faEye,
    faFileCirclePlus,
    faFileMagnifyingGlass,
    faFolderOpen,
    faGripVertical,
    faH1,
    faH2,
    faH3,
    faH4,
    faH5,
    faH1Regular,
    faH2Regular,
    faH3Regular,
    faH4Regular,
    faH5Regular,
    faImage,
    faImageRegular,
    faItalic,
    faListOl,
    faListOlRegular,
    faListUl,
    faListTree,
    faListUlRegular,
    faLock,
    faMagnifyingGlass,
    faPaintBrushFine,
    faParagraph,
    faPen,
    faPenToSquare,
    faPlus,
    regularFaParagraph,
    faShareNodes,
    faSidebar,
    faStrikethrough,
    faTable,
    faTableRegular,
    faTrashCan,
    faUnderline,
    faXmark)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

import './db.js'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { firestorePlugin } from 'vuefire'
import {router} from '@/router'

Vue.use(VueNumericInput)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(firestorePlugin)
Vue.use(VueTippy)
Vue.component('TippyWidget', TippyComponent)
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/material.css'
import 'tippy.js/themes/translucent.css'

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

// Extract the function out, up here, so I'm not writing it twice
const update = (el, binding) => el.style.visibility = (binding.value) ? 'hidden' : ''

/**
 * Hides an HTML element, keeping the space it would have used if it were visible (css: Visibility)
 */
Vue.directive('hide', {
    // Run on initialisation (first render) of the directive on the element
    bind: update,
    // Run on subsequent updates to the value supplied to the directive
    update: update
})

// Guard based on store
router.beforeEach((to, from, next) => {

    if (to.name !== 'Login' && !store.state.user) {
        next({name: 'Login'})
    } else {
        next()
    }
})


// This is awful. Surely there's a better way?
export let app

firebase.auth().onAuthStateChanged((user) => {

    store.dispatch('updateUser', user).then(() => {
        if (!app) {
            app = new Vue({
                render: h => h(App),
                router,
                store,
            }).$mount('#app')
        }
    })

})

