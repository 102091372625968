import {Paragraph as TiptapParagraph} from '@tiptap/extension-paragraph'
import {BulletList as TiptapBulletList} from '@tiptap/extension-bullet-list'
import {OrderedList as TiptapOrderedList} from '@tiptap/extension-ordered-list'
import { Figure } from './figure'
import {CodeBlockLowlight as TiptapCodeBlock} from '@tiptap/extension-code-block-lowlight'
import TipTapTable from '@tiptap/extension-table'
import TipTapTableCell from '@tiptap/extension-table-cell'
import TipTapTableHeader from '@tiptap/extension-table-header'
import createNodeView from '@/extensions/pmnodeview'
import {VueNodeViewRenderer} from '@tiptap/vue-2'
import PMTable from '@/components/PapermillTable'
import PMTableCell from '@/components/PapermillTableCell'
import PMTableHeader from '@/components/PapermillTableHeader'

export const Paragraph = TiptapParagraph.extend({
    draggable: false,
    selectable: false,
    addNodeView() {
        return createNodeView('p', 'para')
    }
})



export const BulletList = TiptapBulletList.extend({
    draggable: false,
    selectable: false,
    addNodeView() {
        return createNodeView('ul', 'ul')
    }
})

export const OrderedList = TiptapOrderedList.extend({
    draggable: false,
    selectable: false,
    addNodeView() {
        return createNodeView('ol', 'ol')
    }
})

export const Table = TipTapTable.extend({
    addAttributes() {
        return {
            layoutWidth: {
                default: 80
            },
        }
    },
    addNodeView() {
        return VueNodeViewRenderer(PMTable)
    },
})

export const TableCell = TipTapTableCell.extend({
    addNodeView() {
        return VueNodeViewRenderer(PMTableCell)
    },
})

export const TableHeader = TipTapTableHeader.extend({
    addNodeView() {
        return VueNodeViewRenderer(PMTableHeader)
    },
})

export const ImageFigure = Figure.extend({
    name: 'imageFigure',
    content: 'image figcaption',
    draggable: true,
    selectable: true,
    isolating: true,
    addNodeView() {
        return createNodeView('figure', 'fig')
    }
})

export const TableFigure = Figure.extend({
    name: 'tableFigure',
    content: 'table figcaption',
    draggable: true,
    selectable: true,
    isolating: true,
    addNodeView() {
        return createNodeView('figure', 'table')
    }
})

export const CodeBlock = TiptapCodeBlock.extend({
    draggable: false,
    selectable: false,
    addNodeView() {
        // Parent tag as needs to be <pre><code> as best practice
        // Spellcheck as html attribs not respected by extension???
        return createNodeView('code', 'code', 'pre', {spellcheck: 'false'})
    }
})
