<template>
    <div class="style-thumbnail-container">  
        <div
            v-for="s in allStyles"
            :key="s.styleId"
        >
            <StyleThumbnail
                :style-id="s.styleId"
                :style-name="s.styleName"
                :img-path="thumbnailPNG(s.styleName)" 
                :style-selected="s.styleId === selected"
                @setSelectStyle="selectStyle"
            />
        </div>
    </div>   
</template>
<script>
import {mapState} from 'vuex'
import StyleThumbnail from './StyleThumbnail.vue'

export default {    
    name: 'StyleThumbnailSelector',
    props: ['size'],
    data() {
        return {
            selected: undefined
        }
    },
    mounted() {
        this.selected = this.currentStyle.id
    },
    methods: {
        selectStyle(id) {
            this.$store.dispatch('selectStyle', id)
        },
        thumbnailPNG(style_name) {
            let name = style_name.toLowerCase().replace(/ /g, '_')+'.png'
            return require(`../assets/thumbnails/${name}`)
        },
    },
    computed: {
        allStyles() {
            let userStyles = Object.entries(this.profile.styles).map(function([key, val]) {
                return {
                    styleId: key,
                    styleName: val.name
                }
            })
            userStyles = userStyles.filter(s => s.styleName)
            userStyles.sort((a,b) => a.styleName.toUpperCase() < b.styleName.toUpperCase() ? -1 : 1)
            return userStyles
        },
        ...mapState(['currentStyle','profile']),
    },
    watch: {
        currentStyle() {
            if (this.currentStyle) {
                this.selected = this.currentStyle.id
            } else {
                this.selected = ''
            }
        }
    },
    components: {StyleThumbnail}
}
</script>

<style scoped lang="scss">
.style-thumbnail-container{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
	width: auto;
    max-width: auto;
	margin: auto;
    overflow-y: auto;
    height: auto;
    gap: 2px;

}

</style>